@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap);
:root {
  --main-light: #f3f4ed;
  --main-dark: #424642;
  --main-mid-dark: #536162;
  --main-bright-brown: #c06014;
  --main-light-sec: #f4f9f9;
  --main-bright-orange: #ffc478;
  --main-bright-red: #eb5e0b;
  --main-bright-green: #5eaaa8;
  --main-bright-green-light: #a3d2ca;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  /* font-family: 'Roboto', sans-serif; */
  /* font-family: 'PT Sans', sans-serif; */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f5f5f5; */
  background-color: #f3f4ed;
  background-color: var(--main-light);
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slick-prev:before,
.slick-next:before {
  color: #424642 !important;
  color: var(--main-dark) !important;
}

/* .slick-slide {
  padding: 0px 65px !important;
} */

.slick-next {
  right: -25px !important;
}
.slick-prev {
  left: -25px !important;
}

.slick-track {
  /* margin: 0 auto !important; */
  display: -webkit-flex !important;
  display: flex !important;
  margin-left: 1px !important;
  margin-right: 1px !important;
}

/* the slides */
.slick-slide {
  /* float: left !important; */
  margin: 0px 15px !important;
  width: 200px !important;
}
/* the parent */
/* .slick-list {
  overflow: hidden !important;
} */

/* .alice-carousel__wrapper {
  width: 98% !important;
} */

progress {
  vertical-align: baseline;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

/* progress - styles for progress-bar in photo upload */

.progress {
  display: -webkit-flex;
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #28a745;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.Layout_Content__1u3u3 {
    margin-top: 72px;
}
.Toolbar_Toolbar__3QW9j {
  height: 70px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  /* background-color: #96a3b1; */
  background-color: var(--main-dark);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 90;
}

.Toolbar_Toolbar__3QW9j nav {
  height: 100%;
}

.Toolbar_Logo__2pLa5 {
  height: 80%;
}

@media (max-width: 780px) {
  .Toolbar_DesktopOnly__2NjFQ {
    display: none;
  }
  .Toolbar_Toolbar__3QW9j {
    height: 56px;
  }
}

.Logo_Logo__2200u {
  /* background-color: #f5f5f5; */
  background-color: var(--main-dark);
  padding: 1px 5px 1px 5px;
  height: 100%;
  box-sizing: border-box;
  border-radius: 5px;
}

.Logo_Logo__2200u img {
  height: 90%;
}

.Logo_LogoSide__Ldj1V {
  background-color: var(--main-dark);
  padding: 1px 5px 1px 5px;
  /* height: 100%; */
  box-sizing: border-box;
  border-radius: 5px;
}

.Logo_LogoSide__Ldj1V img {
  width: 235px;
}

@media (max-width: 699px) {
  .Logo_Logo__2200u img {
    height: 85%;
  }
}

@media (max-width: 400px) {
  .Logo_LogoSide__Ldj1V img {
    width: 150px;
  }
}

.NavigationItems_NavigationItems__iwer4 {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-align-items: center;
          align-items: center;
  height: 100%;
}

@media (min-width: 780px) and (max-width: 1290px) {
  .NavigationItems_HideSm__36QPA {
    display: none;
  }
  .NavigationItems_NavigationItems__iwer4 {
    font-size: 1.7rem;
  }
}

@media (min-width: 780px) {
  .NavigationItems_NavigationItems__iwer4 {
    -webkit-flex-flow: row;
            flex-flow: row;
  }
  /* .HideSm {
    display: none;
  } */
}

.NavigationItem_NavigationItem__aJfgP {
  margin: 10px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;

  /* padding-bottom: 16px !important; */
}

.NavigationItem_NavigationItem__aJfgP a {
  color: var(--main-bright-green);
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
  outline: none;
}

@media (min-width: 780px) {
  .NavigationItem_NavigationItem__aJfgP {
    margin: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: auto;
    height: 70px;
  }

  .NavigationItem_NavigationItem__aJfgP a {
    color: var(--main-light);
    height: 70px;
    padding: 25px 10px;
    border-bottom: 4px solid transparent;
  }

  .NavigationItem_NavigationItem__aJfgP a:hover,
  .NavigationItem_NavigationItem__aJfgP a:active,
  .NavigationItem_NavigationItem__aJfgP a.NavigationItem_active__n4-kR {
    background-color: var(--main-dark);
    border-bottom: 4px solid var(--main-bright-green);
    color: white;
  }
}

.ChangeLanguage_LangBar__PHnY1 {
  display: -webkit-flex;
  display: flex;
}
.ChangeLanguage_LanguageSwitch__1BSgY {
  margin: 10px 0;
  /* margin-left: 15px; */
  box-sizing: border-box;
  display: block;
  width: 100%;
  /* font-size: 1.1rem; */
}

.ChangeLanguage_LanguageButton__3lxiA {
  color: white;
  background-color: var(--main-mid-dark);
  /* background-color: #b6b8b9; */
  border: none;
  width: 45px;
  box-sizing: border-box;
  display: block;
  cursor: pointer;
  /* margin: 2px; */
  outline: none;
}

.ChangeLanguage_LanguageButtonActive__3Zc9V {
  color: white;
  background-color: var(--main-dark);
  border: none;
  width: 45px;
  box-sizing: border-box;
  display: block;
  cursor: pointer;
  /* margin: 2px; */
}

.ChangeLanguage_LanguageButtonActive__3Zc9V:focus {
  outline: 0;
}

/* .LanguageButton:hover,
.LanguageButton:active,
.LanguageButton.active {
  background-color: var(--main-bright-green);
} */

@media (min-width: 780px) {
  .ChangeLanguage_LanguageSwitch__1BSgY {
    margin: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: auto;
    height: 70px;
  }

  .ChangeLanguage_LanguageSwitchActive__3Xcel {
    color: white;
    height: 70px;
    padding: 17px 10px 29px 10px;
    box-sizing: border-box;
    display: block;
    width: 100%;
    font-size: 1.1rem;
  }

  .ChangeLanguage_LanguageButton__3lxiA {
    color: white;
    height: 70px;
    padding: 17px 10px 20px 10px;
    font-size: 1.1rem;
    cursor: pointer;
  }

  .ChangeLanguage_LanguageButtonActive__3Zc9V {
    background-color: var(--main-bright-green);
    height: 70px;
    padding: 17px 10px 20px 10px;
    font-size: 1.1rem;
    /* font-weight: bolder; */
    cursor: pointer;
  }

  /* .LanguageButton:hover,
  .LanguageButton:active,
  .LanguageButton.active {
    font-size: 1.2rem;

    color: white;
  } */

  .ChangeLanguage_LanguageButtonActive__3Zc9V:focus {
    outline: 0;
  }
}

.DrawerToggle_DrawerToggle__251NP {
  width: 40px;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  padding: 10px 0;
  box-sizing: border-box;
  cursor: pointer;
}

.DrawerToggle_DrawerToggle__251NP div {
  width: 90%;
  height: 3px;
  background-color: white;
}

@media (min-width: 780px) {
  .DrawerToggle_DrawerToggle__251NP {
    display: none;
  }
}

.SideDrawer_SideDrawer__1SpuS {
  position: fixed;
  width: 280px;
  max-width: 70%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 200;
  background-color: white;
  padding: 32px 16px;
  box-sizing: border-box;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

@media (min-width: 780px) {
  .SideDrawer_SideDrawer__1SpuS {
    display: none;
  }
}

.SideDrawer_Open__23ihK {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.SideDrawer_Close__2KWUA {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.SideDrawer_Logo__2ybOl {
  height: 11%;
  width: 250px;
  margin-bottom: 32px;
}

@media (max-width: 400px) {
  .SideDrawer_Logo__2ybOl {
    width: 160px;
  }
}

.Backdrop_Backdrop__1qj-c {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 102;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.FooterItems_FooterItems__37QyU {
  margin: 7px 0 0 0;
  padding: 0;
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row;
          flex-flow: row;
  -webkit-align-items: center;
          align-items: center;
  height: 100%;
}

.FooterItems_Email__H0rrq {
  font-size: 0.6rem;
  margin: 0 auto 5px auto;
}

@media (min-width: 500px) {
  .FooterItems_FooterItems__37QyU {
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
  }
  .FooterItems_ShowSmall__3oWC4 {
    display: none;
  }
}

@media (max-width: 499px) {
  .FooterItems_ShowBig__3y31e {
    display: none;
  }
}

.FooterItem_FooterItem__1h9TY {
  margin: 5px 0 0 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
}

.FooterItem_FooterItem__1h9TY a {
  color: white;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  /* display: block; */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  font-size: 12px;

  padding: 0px 10px;
}

.FooterItem_FooterItem__1h9TY a:hover,
.FooterItem_FooterItem__1h9TY a:active,
.FooterItem_FooterItem__1h9TY a.FooterItem_active__1z59r {
  /* color: #40a4c8; */
  text-decoration: underline;
}

@media (min-width: 500px) {
  .FooterItem_FooterItem__1h9TY {
    margin: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: auto;
    height: 100%;
  }

  .FooterItem_FooterItem__1h9TY a {
    color: white;
    height: 100%;
    padding: 16px 10px;
    outline: none;
    font-size: 16px;
    /* border-bottom: 4px solid transparent; */
  }

  .FooterItem_FooterItem__1h9TY a:hover,
  .FooterItem_FooterItem__1h9TY a:active,
  .FooterItem_FooterItem__1h9TY a.FooterItem_active__1z59r {
    background-color: var(--main-dark);
    /* border-bottom: 4px solid #40a4c8; */
    color: white;
  }
}

.Footer_Footer__1FODD {
  height: 56px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: var(--main-dark);
  display: -webkit-flex;
  display: flex;
  /* justify-content: space-between; */
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 90;
}

.Footer_Footer__1FODD nav {
  height: 100%;
}

.Footer_Logo__wmWa1 {
  height: 80%;
}

@media (max-width: 499px) {
  .Footer_Footer__1FODD {
    height: 40px;
  }
}

.Spinner_Loader__3BVIO,
.Spinner_Loader__3BVIO:before,
.Spinner_Loader__3BVIO:after {
  border-radius: 50%;
}
.Spinner_Loader__3BVIO {
  color: var(--main-bright-green);
  font-size: 11px;
  text-indent: -99999em;
  margin: 155px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.Spinner_Loader__3BVIO:before,
.Spinner_Loader__3BVIO:after {
  position: absolute;
  content: '';
}
.Spinner_Loader__3BVIO:before {
  width: 5.2em;
  height: 10.2em;
  background: var(--main-light);
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5.2em 5.1em;
  transform-origin: 5.2em 5.1em;
  -webkit-animation: Spinner_load2__1ABhn 2s infinite ease 1.5s;
  animation: Spinner_load2__1ABhn 2s infinite ease 1.5s;
}
.Spinner_Loader__3BVIO:after {
  width: 5.2em;
  height: 10.2em;
  background: var(--main-light);
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 5.1em;
  -webkit-transform-origin: 0px 5.1em;
  transform-origin: 0px 5.1em;
  -webkit-animation: Spinner_load2__1ABhn 2s infinite ease;
  animation: Spinner_load2__1ABhn 2s infinite ease;
}
@-webkit-keyframes Spinner_load2__1ABhn {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes Spinner_load2__1ABhn {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.Description_Description__2ACEs {
  margin: 25px auto 20px auto;
  width: 85%;
  -webkit-align-items: center;
          align-items: center;
  padding: 2px;
  box-sizing: border-box;
  text-align: right;
}

.Description_Description__2ACEs span {
  margin: 5px auto 15px auto;
  width: 100%;
  color: var(--main-dark);
  font-size: 2em;
  font-weight: bold;
}

.Description_Description__2ACEs hr {
  background-image: linear-gradient(to bottom right, #364f6b);
  height: 3px;
  border: none;
  width: 100% !important;
}

@media (max-width: 699px) {
  .Description_Description__2ACEs {
    margin: 5px auto 20px auto;
  }
  .Description_Description__2ACEs span {
    font-size: 1.1em;
  }
}

.Statistics_Statistics__21T6n {
  width: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  margin: 10px;
  /* max-width: 1100px; */
  /* background-color: #e1eaf5; */
}

h4 {
  color: #364f6b;
}

.Statistics_Statistics__21T6n a {
  color: #364f6b;
}

.Statistics_Statistics__21T6n a:hover {
  color: #40a4c8;
}

.Statistics_Statistics__21T6n span {
  display: inline-block;
  padding: 10px;
}

.Statistics_Statistics__21T6n h4 {
  display: inline-block;
  /* padding: 10px; */
}

@media (max-width: 599px) {
  .Statistics_Statistics__21T6n span {
    padding: 3px;
  }
  .Statistics_Statistics__21T6n {
    margin: 3px 0 30px 0;
  }
}

.GalleryMainPage_Container__2q4En {
  margin: 85px auto 60px auto;
  width: 95%;
  -webkit-align-items: center;
          align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

.GalleryMainPage_GalleryImage__23z8B {
  width: 100%;
  padding: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: center;
          justify-content: center;
}
/* .CatCards {
  display: block;
} */

.GalleryMainPage_ContainerAll__1lRnb {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 20px auto;
  height: auto;
}

.GalleryMainPage_Container__2q4En hr {
  background-image: linear-gradient(
    to bottom right,
    var(--main-dark),
    var(--main-light)
  );
  height: 3px;
  border: none;
  margin: 10px auto 10px auto;
  width: 85%;
}

@media (max-width: 699px) {
  .GalleryMainPage_Container__2q4En {
    margin: 70px auto 40px auto;
  }
  .GalleryMainPage_GalleryImage__23z8B {
    padding: 1px;
  }
}

/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@500&display=swap'); */

.TitleBlock_PageTitle__2rp2T {
  margin: 0px auto 20px auto;
  width: 85%;
  -webkit-align-items: center;
          align-items: center;
  padding: 2px;
  box-sizing: border-box;
  text-align: right;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
}

.TitleBlock_PageTitleSpan__3oTGJ {
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Montserrat', sans-serif;
  /* font-family: 'Comfortaa', cursive; */
  margin: 5px 0px 5px 0px;
  width: 100%;
  color: var(--main-dark);
  font-size: 2em;
  font-weight: bold;
}

.TitleBlock_AddInfoSpan__xv3DU {
  margin: 3px 0px 3px 0px;
  width: 100%;
  color: var(--main-dark);
  font-size: 1.2em;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
}

.TitleBlock_PageTitle__2rp2T hr {
  background-image: linear-gradient(to bottom right, var(--main-dark));
  height: 3px;
  border: none;
  margin-top: 3px;
  width: 100%;
}

@media (max-width: 699px) {
  .TitleBlock_PageTitleSpan__3oTGJ {
    font-size: 1.2em;
  }
  .TitleBlock_AddInfoSpan__xv3DU {
    font-size: 1em;
  }
}

.Categories_Category__38Hpw {
  max-width: 1600px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  /* align-items: flex-start; */
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0.9em;
  margin: 0 auto 0 auto;
  width: 85%;
}

.Categories_Container__10PBJ {
  margin: 95px auto 60px auto;
  width: 95%;
  -webkit-align-items: center;
          align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

@media (max-width: 699px) {
  .Categories_Category__38Hpw {
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    padding: 0;
    -webkit-align-items: center;
            align-items: center;
  }

  .Categories_Container__10PBJ {
    margin: 70px auto 60px auto;
  }
}

.Pagination_Pagination__2ssNp {
  margin: 15px auto 65px auto;
  width: 90%;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 2px;
  box-sizing: border-box;
  height: 45px;
  display: -webkit-flex;
  display: flex;
  position: fixed;
  bottom: -10px;
  left: 0px;
  width: 100%;
  z-index: 1;
  /* background-color: white; */
}

.Pagination_WhiteLine__1vMLQ {
  background-color: white;
  border-top: 1px solid #ddd;
  width: 100%;
  height: 125px;
  position: fixed;
  bottom: -10px;
  left: 0px;
  opacity: 0.9;
}

.Pagination_Pagination__2ssNp span {
  background-color: white;
  height: auto;
  cursor: pointer;
  color: black;
  width: auto;
  -webkit-flex-flow: row;
          flex-flow: row;
  padding: 10px 16px 10px 16px;
  text-decoration: none;
  transition: 0.2s ease-in;
  border: 1px solid #ddd;
}

.Pagination_Pagination__2ssNp a {
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.Pagination_Pagination__2ssNp span.Pagination_active__1xAW7 {
  /* background-color: #40a4c8; */
  background-color: var(--main-bright-green);
  color: white;
  /* border: 1px solid #40a4c8; */
  border: 1px solid var(--main-bright-green);
}

@media (max-width: 499px) {
  .Pagination_Pagination__2ssNp {
    margin: 15px auto 50px auto;
  }
  .Pagination_WhiteLine__1vMLQ {
    height: 115px;
  }
}

/* @media (max-width: 350px) {
  .Pagination span {
    flex-flow: column;
  }
} */

.Select_Select__1z7gV {
  width: 300px;
}

.Category_Category__21WZX {
  margin: 0 auto 70px auto;
  max-width: 1600px;
  width: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-justify-content: left;
          justify-content: left;
  padding: 0.9em;
}

.Category_Container__3EhTr {
  margin: 95px auto 40px auto;
  width: 95%;
  -webkit-align-items: center;
          align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

.Category_SlideCard__6LROP {
  padding: 10px;
}

/* .FormGroup select {

  width: 250px;
  padding: 5px 35px 5px 5px;
  font-size: 16px;

  border: none;
  height: 34px;
  background-color: #f5f5f5;
} */

.Category_Dropdown__BJHjp {
  margin: 0 auto 0 auto;
  max-width: 1600px;
  min-width: 300px;
  width: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-justify-content: left;
          justify-content: left;
  padding: 0.2em;
}

.Category_DropdownPanel__DTo4W {
  margin: 0 auto 0 auto;
  width: 90%;
  background-color: #f5f5f5;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: left;
          justify-content: left;
  padding: 0 20px;
  /* margin: 5px 0 5px 0; */
  box-sizing: border-box;
  z-index: 90;
}

@media (max-width: 699px) {
  .Category_Category__21WZX {
    margin: 0 auto 50px auto;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .Category_DropdownPanel__DTo4W {
    padding: 0;
  }
  .Category_DropdownPanel__DTo4W {
    -webkit-flex-flow: column;
            flex-flow: column;
  }
  .Category_Dropdown__BJHjp {
    margin: 0 auto;
  }
}

.CategoryTime_Category__3MpBt {
  margin: 0 auto 70px auto;
  max-width: 1600px;
  width: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-justify-content: left;
          justify-content: left;
  padding: 0.9em;
}

.CategoryTime_Dropdown__1mZ5a {
  margin: 0 auto 0 auto;
  max-width: 1600px;
  width: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-justify-content: left;
          justify-content: left;
  padding: 0.2em;
}

.CategoryTime_DropdownPanel__35o3E {
  margin: 0 auto 0 auto;
  width: 90%;
  background-color: var(--main-light);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: left;
          justify-content: left;
  padding: 0 20px;
  /* margin: 5px 0 5px 0; */
  box-sizing: border-box;
  z-index: 90;
}

.CategoryTime_Container__3VixM {
  margin: 95px auto 40px auto;
  width: 95%;
  -webkit-align-items: center;
          align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

.CategoryTime_Container__3VixM h2 {
  padding: 40px;
}

.CategoryTime_SlideCard__11wMK {
  padding: 10px;
}

.CategoryTime_Select__R1jFv {
  width: 350px;
}

@media (max-width: 699px) {
  .CategoryTime_Category__3MpBt {
    margin: 0 auto 50px auto;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .CategoryTime_DropdownPanel__35o3E {
    padding: 0;
    width: 100%;
  }
  .CategoryTime_Dropdown__1mZ5a {
    margin: 0 auto;
  }
  .CategoryTime_Container__3VixM {
    margin: 70px auto 40px auto;
  }
}

.Albums_Albums__1lsAs {
  margin: 0 auto 60px auto;
  max-width: 1000px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0.9em;
}

.Albums_Albums__1lsAs a {
  color: var(--main-mid-dark);
}

.Albums_Albums__1lsAs a:hover {
  color: var(--main-bright-green);
}
.Albums_Container__3cRwp {
  margin: 95px auto 60px auto;
  width: 95%;
  -webkit-align-items: center;
          align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

@media (max-width: 699px) {
  .Albums_Container__3cRwp {
    margin: 70px auto 40px auto;
  }
}

.AlbumInfo_AlbumInfo__3Y2yS {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  max-width: 1200px;
  border-bottom: 1px solid rgb(226, 225, 225);
  padding: 15px;
  box-sizing: border-box;
}

.AlbumInfo_AlbumInfo__3Y2yS span {
  text-align: left;
  font-size: 1rem;
}

.AlbumInfo_AlbumInfo__3Y2yS a {
  outline: none;
}

@media (max-width: 699px) {
  .AlbumInfo_AlbumInfo__3Y2yS span {
    font-size: 0.7rem;
  }
  .AlbumInfo_AlbumInfo__3Y2yS {
    padding: 5px;
  }
}

.Album_Album__quS6j {
  margin: 0 auto 70px auto;
  max-width: 1600px;
  width: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-justify-content: left;
          justify-content: left;
  padding: 0.9em;
}

.Album_Container__BdKAe {
  margin: 95px auto 40px auto;
  width: 95%;
  -webkit-align-items: center;
          align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

@media (max-width: 699px) {
  .Album_Album__quS6j {
    margin: 0 auto 50px auto;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .Album_Container__BdKAe {
    margin: 70px auto 40px auto;
  }
}

.Locations_Locations__1Vkcs {
  max-width: 1600px;
  width: 84%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  padding: 0.9em;
  margin: 0px auto 0px auto;
}

.Locations_Container__2V9Sp {
  margin: 95px auto 60px auto;
  width: 95%;
  -webkit-align-items: center;
          align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

.Locations_SearchBar__3HEl5 {
  width: 80%;
  max-width: 800px;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 60px auto 25px auto;
  border: 3px solid var(--main-bright-green);
  padding: 5px;
  height: 36px;
  border-radius: 5px;
  outline: none;
  box-sizing: border-box;
}

.Locations_SearchTerm__1T7Zd:focus {
  color: var(--main-bright-green);
}

@media (max-width: 699px) {
  .Locations_Locations__1Vkcs {
    width: 95%;
  }
  .Locations_Container__2V9Sp {
    margin: 70px auto 60px auto;
  }
  .Locations_SearchBar__3HEl5 {
    margin: 20px auto 15px auto;
  }
}

.Location_Location__31eTh {
  margin: 0 auto 70px auto;
  max-width: 1600px;
  width: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-justify-content: left;
          justify-content: left;
  /* justify-content: center; */
  padding: 0.9em;
}

.Location_Container__171C4 {
  margin: 95px auto 40px auto;
  width: 95%;
  -webkit-align-items: center;
          align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

.Location_Dropdown__9kyPs {
  margin: 0 auto 0 auto;
  max-width: 300px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-justify-content: left;
          justify-content: left;
  padding: 0.2em;
}

.Location_DropdownPanel__L1Ab3 {
  margin: 0 auto 0 auto;
  width: 90%;
  background-color: var(--main-light);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: left;
          justify-content: left;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 90;
}

.Location_SlideCard__2BqHM {
  padding: 10px;
}

@media (max-width: 699px) {
  .Location_DropdownPanel__L1Ab3 {
    padding: 0;
  }
  .Location_DropdownPanel__L1Ab3 {
    -webkit-flex-flow: column;
            flex-flow: column;
    width: 100%;
  }
  .Location_Location__31eTh {
    margin: 0 auto 50px auto;
    -webkit-justify-content: center;
            justify-content: center;
  }
}

.LocationCat_Location__ADwVZ {
  margin: 0 auto 70px auto;
  max-width: 1600px;
  width: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-justify-content: left;
          justify-content: left;
  padding: 0.9em;
}

.LocationCat_Dropdown__118qj {
  margin: 0 auto 0 auto;
  max-width: 1600px;
  width: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-justify-content: left;
          justify-content: left;
  padding: 0.2em;
}

.LocationCat_DropdownPanel__G7C89 {
  margin: 0 auto 0 auto;
  width: 90%;
  background-color: var(--main-light);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: left;
          justify-content: left;
  padding: 0 20px;
  /* margin: 5px 0 5px 0; */
  box-sizing: border-box;
  z-index: 90;
}

.LocationCat_Container__2tx9l {
  margin: 95px auto 40px auto;
  width: 95%;
  -webkit-align-items: center;
          align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

.LocationCat_Container__2tx9l h2 {
  padding: 40px;
}

.LocationCat_SlideCard__sPu4l {
  padding: 10px;
}

@media (max-width: 699px) {
  .LocationCat_Location__ADwVZ {
    margin: 0 auto 50px auto;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .LocationCat_DropdownPanel__G7C89 {
    padding: 0;
    width: 100%;
  }
  .LocationCat_Dropdown__118qj {
    margin: 0 auto;
  }
  .LocationCat_Container__2tx9l {
    margin: 70px auto 40px auto;
  }
}

.LocationTime_Location__tULiE {
  margin: 0 auto 70px auto;
  max-width: 1600px;
  width: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-justify-content: left;
          justify-content: left;
  padding: 0.9em;
}

.LocationTime_Dropdown__3_kcj {
  margin: 0 auto 0 auto;
  max-width: 1600px;
  width: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-justify-content: left;
          justify-content: left;
  padding: 0.2em;
}

.LocationTime_DropdownPanel__v5IUp {
  margin: 0 auto 0 auto;
  width: 90%;
  background-color: var(--main-light);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: left;
          justify-content: left;
  padding: 0 20px;
  /* margin: 5px 0 5px 0; */
  box-sizing: border-box;
  z-index: 90;
}

.LocationTime_Container__1LSmN {
  margin: 95px auto 40px auto;
  width: 95%;
  -webkit-align-items: center;
          align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

.LocationTime_Container__1LSmN h2 {
  padding: 40px;
}

.LocationTime_SlideCard__SVVp8 {
  padding: 10px;
}

@media (max-width: 699px) {
  .LocationTime_Location__tULiE {
    margin: 0 auto 50px auto;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .LocationTime_DropdownPanel__v5IUp {
    padding: 0;
    width: 100%;
  }
  .LocationTime_Dropdown__3_kcj {
    margin: 0 auto;
  }
  .LocationTime_Container__1LSmN {
    margin: 70px auto 40px auto;
  }
}

.Periods_Periods__1hdIV {
  max-width: 1000px;
  width: 87%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  padding: 0.9em;
  margin: 0px auto 0px auto;
}

.Periods_Periods__1hdIV a {
  color: var(--main-mid-dark);
}

.Periods_Periods__1hdIV a:hover {
  color: var(--main-bright-green);
}

.Periods_Container__3K6h_ {
  margin: 95px auto 60px auto;
  width: 95%;
  -webkit-align-items: center;
          align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

@media (max-width: 699px) {
  .Periods_Container__3K6h_ {
    margin: 70px auto 40px auto;
  }
}

* {
  box-sizing: border-box;
}

.CardThumb_Card__P1TPc {
  margin: 0.9em;
  max-width: 145em;
  overflow: hidden;
  border-bottom: 1px solid rgb(226, 225, 225);
}

.CardThumb_Card__P1TPc:hover {
  border-bottom: 1px solid var(--main-bright-green);
}

.CardThumb_CardBody__2jrN9 {
  width: 22em;
  max-height: 100%;
  padding: 0.8em;
}

.CardThumb_CardText__1v0An {
  margin: 0.8em 0 0.8em 0;
  padding-left: 0.8em;
}

.CardThumb_CardText__1v0An h2,
h3,
p {
  margin-top: 0.5rem;
}

.CardThumb_CardText__1v0An h2 {
  margin-bottom: 0.5rem;
}

a {
  color: var(--main-bright-green);
  text-decoration: none;
}

.CardThumb_CardImage__2bWAG {
  height: 100%;
  max-height: 12em;
  width: 250px;
  object-fit: cover;
  border-radius: 5px;
}

@media (max-width: 1100px) {
  .CardThumb_Card__P1TPc {
    margin: 0.78em;
  }
  .CardThumb_CardText__1v0An h2,
  h3,
  p {
    margin-top: 0;
    font-size: 0.9em;
  }
  .CardThumb_CardText__1v0An {
    margin: 0;
  }
}

@media (max-width: 870px) {
  .CardThumb_Card__P1TPc {
    max-width: 100%;
  }
  .CardThumb_CardText__1v0An {
    margin: 0;
  }
}

@media (max-width: 799px) {
  .CardThumb_Card__P1TPc {
    margin: 0.75em 1.1em;
    /* height: 10em; */
  }
  .CardThumb_Image__2zJRw {
    max-width: 40%;
  }

  .CardThumb_Total__1anwh {
    font-size: 0.8em;
  }
  .CardThumb_CardText__1v0An {
    margin: 0;
  }
}

.PeriodCat_Period__3SYIn {
  margin: 0 auto 70px auto;
  max-width: 1600px;
  width: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-justify-content: left;
          justify-content: left;
  padding: 0.9em;
}

.PeriodCat_Dropdown__2gP8p {
  margin: 0 auto 0 auto;
  max-width: 1600px;
  width: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-justify-content: left;
          justify-content: left;
  padding: 0.2em;
}

.PeriodCat_DropdownPanel__1Wokc {
  margin: 0 auto 0 auto;
  width: 90%;
  background-color: #f5f5f5;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: left;
          justify-content: left;
  padding: 0 20px;
  /* margin: 5px 0 5px 0; */
  box-sizing: border-box;
  z-index: 90;
}

.PeriodCat_Container__34uRS {
  margin: 95px auto 40px auto;
  width: 95%;
  -webkit-align-items: center;
          align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

.PeriodCat_Container__34uRS h2 {
  padding: 40px;
}

.PeriodCat_SlideCard__3xF0E {
  padding: 10px;
}

@media (max-width: 699px) {
  .PeriodCat_Period__3SYIn {
    margin: 0 auto 50px auto;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .PeriodCat_DropdownPanel__1Wokc {
    padding: 0;
    width: 100%;
  }
  .PeriodCat_Dropdown__2gP8p {
    margin: 0 auto;
  }
  .PeriodCat_Container__34uRS {
    margin: 70px auto 40px auto;
  }
}

.Period_Period__2Z-pI {
  margin: 0 auto 70px auto;
  max-width: 1600px;
  width: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-justify-content: left;
          justify-content: left;
  padding: 0.9em;
}

.Period_Container__8jpxt {
  margin: 95px auto 40px auto;
  width: 95%;
  -webkit-align-items: center;
          align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

.Period_SlideCard__10F4b {
  padding: 10px;
}

.Period_Dropdown__31zj3 {
  margin: 0 auto 0 auto;
  max-width: 1600px;
  min-width: 300px;
  width: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-justify-content: left;
          justify-content: left;
  padding: 0.2em;
}

.Period_DropdownPanel__1DzmY {
  margin: 0 auto 0 auto;
  width: 90%;
  background-color: #f5f5f5;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: left;
          justify-content: left;
  padding: 0 20px;
  /* margin: 5px 0 5px 0; */
  box-sizing: border-box;
  z-index: 90;
}

@media (max-width: 699px) {
  .Period_Period__2Z-pI {
    margin: 0 auto 50px auto;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .Period_DropdownPanel__1DzmY {
    padding: 0;
  }
  .Period_DropdownPanel__1DzmY {
    -webkit-flex-flow: column;
            flex-flow: column;
  }
  .Period_Dropdown__31zj3 {
    margin: 0 auto;
  }
}

.About_About__2EDVs {
  margin: 10px auto 10px auto;
  max-width: 1600px;
  width: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0.9em;
}

.About_Container__3f1qW {
  margin: 15px auto 60px auto;
  width: 95%;
  -webkit-align-items: center;
          align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

.About_About__2EDVs span {
  text-align: left;
  font-size: 1.3rem;
}

.Terms_Terms__17pMR {
  margin: 10px auto 10px auto;
  max-width: 1600px;
  width: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0.9em;
}

.Terms_Container__136j3 {
  margin: 15px auto 40px auto;
  width: 95%;
  -webkit-align-items: center;
          align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

.Terms_Terms__17pMR span {
  text-align: left;
  font-size: 1.3rem;
}

.Contributors_Contributors__Toz1w {
  max-width: 1600px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  padding: 0.9em;
  margin: 0 auto 0 auto;
  width: 87%;
}

.Contributors_BlockTitle__21hmo {
  text-align: left;
  width: 80%;
  color: var(--main-mid-dark);
  /* display: block; */
}

.Contributors_Container__2OYNr {
  margin: 95px auto 60px auto;
  width: 95%;
  -webkit-align-items: center;
          align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

@media (max-width: 699px) {
  .Contributors_Contributors__Toz1w {
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    padding: 0;
  }

  .Contributors_Container__2OYNr {
    margin: 70px auto 60px auto;
  }
}

.Contributor_Contributor__2rLzt {
  margin: 0 auto 70px auto;
  max-width: 1600px;
  width: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-justify-content: left;
          justify-content: left;
  padding: 0.9em;
}

.Contributor_Container__2AOQp {
  margin: 95px auto 40px auto;
  width: 95%;
  -webkit-align-items: center;
          align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

.Contributor_SlideCard__26WT2 {
  padding: 10px;
}

@media (max-width: 699px) {
  .Contributor_Contributor__2rLzt {
    margin: 0 auto 50px auto;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .Contributor_Container__2AOQp {
    margin: 70px auto 40px auto;
  }
}

.Contribute_Contribute__2_9Pq {
  margin: 10px auto 10px auto;
  max-width: 1600px;
  width: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0.9em;
}

.Contribute_Container__2owEM {
  margin: 15px auto 60px auto;
  width: 95%;
  -webkit-align-items: center;
          align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

.Contribute_Contribute__2_9Pq span {
  text-align: left;
  font-size: 1.3rem;
}

.Latest_Latest__3fSxw {
  margin: 10px auto 70px auto;
  max-width: 1600px;
  width: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  /* justify-content: space-between; */
  -webkit-justify-content: left;
          justify-content: left;
  padding: 0.9em;
}

.Latest_Container__1SJh1 {
  margin: 15px auto 40px auto;
  width: 95%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 2px;
  box-sizing: border-box;
}

.Latest_SlideCard__35JK4 {
  padding: 10px;
}

@media (max-width: 699px) {
  .Latest_Latest__3fSxw {
    margin: 10px auto 50px auto;
    -webkit-justify-content: center;
            justify-content: center;
  }
}

.SpinnerSmall_Loader__2kWQW,
.SpinnerSmall_Loader__2kWQW:before,
.SpinnerSmall_Loader__2kWQW:after {
  border-radius: 50%;
}
.SpinnerSmall_Loader__2kWQW {
  color: var(--main-bright-green);
  font-size: 11px;
  text-indent: -99999em;
  margin: 75px auto;
  position: relative;
  width: 5em;
  height: 5em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.SpinnerSmall_Loader__2kWQW:before,
.SpinnerSmall_Loader__2kWQW:after {
  position: absolute;
  content: '';
}
.SpinnerSmall_Loader__2kWQW:before {
  width: 2.6em;
  height: 5.1em;
  background: var(--main-light);
  border-radius: 5.1em 0 0 5.1em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 2.6em 2.55em;
  transform-origin: 2.6em 2.55em;
  -webkit-animation: SpinnerSmall_load2__ldGRr 2s infinite ease 1.5s;
  animation: SpinnerSmall_load2__ldGRr 2s infinite ease 1.5s;
}
.SpinnerSmall_Loader__2kWQW:after {
  width: 2.6em;
  height: 5.1em;
  background: var(--main-light);
  border-radius: 0 5.1em 5.1em 0;
  top: -0.1em;
  left: 2.55em;
  -webkit-transform-origin: 0px 2.55em;
  transform-origin: 0px 2.55em;
  -webkit-animation: SpinnerSmall_load2__ldGRr 2s infinite ease;
  animation: SpinnerSmall_load2__ldGRr 2s infinite ease;
}
@-webkit-keyframes SpinnerSmall_load2__ldGRr {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes SpinnerSmall_load2__ldGRr {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.SearchBar_SearchBar__21ZP4 {
  width: 80%;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 60px auto 25px auto;
  /* box-shadow: 0 2px 3px #ccc;
  border: 1px solid #eee; */
  padding: 10px;
  box-sizing: border-box;
}

.SearchBar_SearchTerm__3CqUY {
  width: 70%;
  border: 3px solid var(--main-bright-green);
  border-right: none;
  padding: 5px;
  height: 36px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9dbfaf;
}

.SearchBar_SearchTerm__3CqUY:focus {
  color: var(--main-bright-green);
}

.SearchBar_SearchButton__1Vd2A {
  width: 40px;
  height: 36px;
  border: 1px solid var(--main-bright-green);
  background: var(--main-bright-green);
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}

@media (max-width: 699px) {
  .SearchBar_SearchBar__21ZP4 {
    width: 95%;
    margin: 30px auto 25px auto;
  }
  .SearchBar_SearchTerm__3CqUY {
    width: 80%;
  }
}

.ShowSearchResultsBlock_ShowCards__1sKPZ {
  margin: 10px auto 10px auto;
  min-height: 100vh;
  max-width: 1600px;
  width: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-justify-content: left;
          justify-content: left;
  padding: 0.9em;
}

.ShowSearchResultsBlock_Container__1a51z {
  margin: 15px auto 40px auto;
  width: 95%;
  -webkit-align-items: center;
          align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

.ShowSearchResultsBlock_SlideCard__1jY2J {
  padding: 10px;
  /* flex-grow: 1; */
}

@media (max-width: 699px) {
  .ShowSearchResultsBlock_ShowCards__1sKPZ {
    -webkit-justify-content: center;
            justify-content: center;
  }
}

.Photo_Container__LlMN3 {
  margin: 10px auto 70px auto;
  width: 95%;
  -webkit-align-items: center;
          align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

.Photo_PhotoPage__2x6tI {
  margin: 0 auto 0 auto;
  max-width: 1500px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 2px 5px 10px 5px;
  box-sizing: border-box;
}

.Photo_PhotoDisplay__4akhY {
  margin: 0 auto 0 auto;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding: 0 5px 10px 5px;
  box-sizing: border-box;
}

.Photo_PhotoAlbDisplay__zWd-H {
  /* margin: 0 auto 0 auto; */
  margin: 0 0 0 -8px;
  width: 100%;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.Photo_Viewer__1q6Lq {
  margin: 0 auto 0 auto;
  width: 99%;
  height: 600px;
  text-decoration: none;
  border: 5px solid #eee;
  padding: 2px 2px 2px 2px;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.Photo_ViewerHidden__1A_gm {
  display: none;
}

.Photo_ViewerSpinner__tTGve {
  margin: 0 auto 0 auto;
  width: 99%;
  height: 600px;
  text-decoration: none;
  padding: 2px 2px 2px 2px;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.Photo_Caption__2ngFG {
  text-align: center;
  margin: 65px auto 15px auto;
  width: 80%;
  min-height: 70px;
}

.Photo_Caption__2ngFG span {
  color: var(--main-dark);
  font-size: 2em;
  font-weight: bold;
  text-align: center;
}

.Photo_PhotoContainer__1EwB6 {
  display: inline-block;
  margin: 0 0 0 0;
  width: 100%;
  box-shadow: 0 2px 3px #ccc;
  padding: 5px;
  box-sizing: border-box;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.Photo_PhotoDescriptionBlock__34EsX {
  width: 100%;
  max-width: 420px;
  min-width: 300px;
  min-height: 632px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  /* box-sizing: border-box; */
  padding-top: 2rem;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.Photo_PhotoDescription__1WuPK {
  -webkit-flex: 1 1;
          flex: 1 1;
  margin: 0 5px;
  width: 100%;
  min-width: 300px;
  border-top: 1px solid lightgrey;
  padding: 15px;
  box-sizing: border-box;
  -webkit-justify-content: left;
          justify-content: left;
  text-align: left;
  line-height: 1.4;
  /* background-color: #ebedef; */
  /* background-color: var(--main-mid-dark); */
  /* background-color: rgba(83, 97, 98, 0.3); */
  border-radius: 3px;
}

.Photo_PhotoDescriptionDescr__HoKfp {
  -webkit-flex: 1 1;
          flex: 1 1;
  margin: 0 5px;
  width: 100%;
  min-width: 300px;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  min-height: 150px;

  padding: 15px;
  box-sizing: border-box;
  -webkit-justify-content: left;
          justify-content: left;
  text-align: left;
  font-size: 1.1rem;
  /* background-color: #ebedef; */
  /* background-color: rgba(83, 97, 98, 0.3); */
  border-radius: 3px;
}

.Photo_PhotoDescriptionAlb__mtGhW {
  display: inline-block;
  margin: 10px 0 10px 0;
  width: 100%;
  max-width: 1500px;
  border-bottom: 1px solid rgb(226, 225, 225);

  padding: 15px 5px 15px 5px;
  box-sizing: border-box;
  font-size: 1.1rem;
  background-color: var(--main-light);
  border-radius: 3px;
}

.Photo_PhotoDescriptionDescr__HoKfp span {
  text-align: left;
  font-size: 1rem;
  font-weight: bold;
}

.Photo_PhotoDescription__1WuPK ul {
  list-style: none;
}

.Photo_BlockTitle__2LskI {
  text-align: left;
  margin: 5px auto 5px auto;
  width: 80%;
}

.Photo_PhotoDescription__1WuPK a {
  outline: none;
}

.Photo_PhotoPage__2x6tI a {
  outline: none;
}

.Photo_Message__3ke32 h4,
h5 {
  text-align: center;
}

.Photo_Message__3ke32 h4 {
  padding-top: 70px;
}

/* .MiniSiteMap {
  display: flex;
  flex-flow: row;
  width: 100%;
  background-color: LightSlateGray;
  height: 37px;
  position: fixed;
  margin: -3px 0 15px 0;
  border-top: 7px solid rgba(255, 255, 255, 0.8);
  background-clip: padding-box;
} */

.Photo_albumNavEmpty__eAFM7 {
  width: 100%;
  height: 65px;
}

@media (max-width: 1200px) {
  .Photo_PhotoDescriptionBlock__34EsX {
    -webkit-flex-flow: row;
            flex-flow: row;
    max-width: 1200px;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    padding-top: 5px;
  }

  .Photo_PhotoAlbDisplay__zWd-H {
    margin: 0 0 0 0;
  }

  .Photo_PhotoDisplay__4akhY {
    -webkit-flex-flow: column;
            flex-flow: column;
  }
  .Photo_PhotoDescriptionDescr__HoKfp {
    max-width: 1000px;
  }

  .Photo_PhotoDescription__1WuPK {
    max-width: 1000px;
    border-top: none;
  }
  .Photo_PhotoDescriptionAlb__mtGhW {
    width: 98%;
  }
  .Photo_PhotoDescriptionDescr__HoKfp span {
    text-align: left;
    font-size: 1.1rem;
    font-weight: bold;
  }
}

@media (max-width: 699px) {
  .Photo_Viewer__1q6Lq {
    width: 100%;
    height: 400px;
  }

  .Photo_PhotoDescription__1WuPK {
    margin: 5px 0;
    border-top: none;
  }
  .Photo_PhotoDescriptionDescr__HoKfp {
    margin: 5px 0;
    min-height: -webkit-min-content;
    min-height: -moz-min-content;
    min-height: min-content;
  }

  .Photo_PhotoDescriptionDescr__HoKfp span {
    font-size: 1rem;
    margin: 0;
  }
  .Photo_PhotoDescriptionBlock__34EsX {
    -webkit-flex-flow: column;
            flex-flow: column;
    margin: 0;
    padding-top: 5px;
  }
  .Photo_PhotoAlbDisplay__zWd-H {
    margin: 0 0 0 0;
  }
  .Photo_PhotoDescriptionAlb__mtGhW {
    width: 98%;
  }

  .Photo_Caption__2ngFG {
    margin: 5px auto 5px auto;
  }

  .Photo_Caption__2ngFG span {
    font-size: 1.5em;
  }
  .Photo_MiniSiteMap__3J09r {
    display: none;
    /* flex-flow: column;
    min-height: 113px; */
  }
}

.NotFound_Container__1vA_X {
  margin: 95px auto 40px auto;
  width: 95%;
  -webkit-align-items: center;
          align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

.MiniSiteMap_MiniSiteMap__2WJlR {
  width: 100%;
  position: fixed;
  top: 73px;
  left: 0;
  background-color: var(--main-mid-dark);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: left;
          justify-content: left;
  padding: 0 40px;
  margin: -3px 0 15px 0;
  box-sizing: border-box;
  z-index: 90;
  border-top: 7px solid rgba(255, 255, 255, 0.8);
  background-clip: padding-box;
}

.MiniSiteMap_MapElement__1_V3Y {
  display: inline-block;
  padding: 5px 10px 5px 10px;
  color: white;
}

.MiniSiteMap_MapElementBack__2ZimT {
  /* background-color: #b6b8b9; */
  display: inline-block;
  padding: 5px 0px 0px 0px;
  height: 100%;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.MiniSiteMap_MapElementBack__2ZimT span {
  /* background-color: #b6b8b9; */

  color: white;
  height: 100%;
  padding: 0 10px 3px 0;
}

.MiniSiteMap_MapElementBack__2ZimT:hover {
  border-bottom: 2px solid var(--main-bright-green);
}

.MiniSiteMap_MapElement__1_V3Y a {
  /* color: white; */
  color: white;
  height: 100%;
  padding-bottom: 3px;
  border-bottom: 2px solid transparent;
  text-decoration: none;
}

.MiniSiteMap_MapElement__1_V3Y a:hover,
.MiniSiteMap_MapElement__1_V3Y a:active,
.MiniSiteMap_MapElement__1_V3Y a.MiniSiteMap_active__3GFkK {
  border-bottom: 2px solid #40a4c8;
}

@media (max-width: 699px) {
  .MiniSiteMap_MiniSiteMap__2WJlR {
    display: none;
    /* flex-flow: column; */
  }
}

/* @media (max-width: 500px) {
  .MiniSiteMap {
    display: none;
    flex-flow: column;
  }
  .MapElement {
    padding: 1px 0px 1px 0px;
  }
} */

.PhotoDownload_PhotoDownload__1Bb_b {
  display: -webkit-flex;
  display: flex;
  /* display: inline-block; */
  -webkit-flex-flow: row;
          flex-flow: row;
  margin: 1px auto 10px auto;
  width: 100%;
  /* padding: 15px; */
  box-sizing: border-box;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.PhotoDownload_IconPhotoDownload__3qwbk {
  /* display: block; */
  color: var(--main-bright-green);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-right: 15px;
}

.PhotoDownload_PhotoDownload__1Bb_b h3 {
  /* display: block; */
  padding: 1px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.DownBlocks_DownBlocks__3f9hk {
  margin: 10px auto 10px auto;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  /* flex-wrap: wrap; */
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-flow: row;
          flex-flow: row;
  padding: 10px;
  box-sizing: border-box;
  background-color: rgba(83, 97, 98, 0.1);
}

.DownBlocks_DownBlock__39jVu {
  margin: 10px 5px;
  width: 280px;
  min-width: 280px;
  height: 80px;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  /* box-shadow: 0 2px 3px #ccc; */
  border: 1px solid #ffffe0;
  padding: 10px;
  cursor: pointer;
  background-color: #ffffe0;
  transition: opacity 0.2s ease-in;
}

.DownBlocks_DownBlockLicense__2W-yH {
  margin: 10px 5px;
  width: 280px;
  min-width: 280px;
  height: 80px;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  /* box-shadow: 0 2px 3px #ccc; */
  /* border: 1px solid #b0e0e6; */
  border: 1px solid var(--main-bright-orange);
  padding: 10px;
  /* background-color: #b0e0e6; */
  background-color: var(--main-light-sec);
  /* color: white; */
  color: var(--main-bright-brown);
}

.DownBlocks_DownBlockHelp__2A7a3 {
  /* background-color: #d4efdf; */
  background-color: var(--main-bright-green-light);
  margin: 10px 5px;
  width: 280px;
  min-width: 280px;
  height: 80px;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  /* box-shadow: 0 2px 3px #ccc; */
  border: 1px solid var(--main-bright-green-light);
  padding: 10px;
  cursor: pointer;
  transition: opacity 0.2s ease-in;
}

.DownBlocks_DownBlockHelp__2A7a3:hover {
  opacity: 0.8;
}

.DownBlocks_DownBlock__39jVu:hover {
  opacity: 0.8;
}

.DownBlocks_DownBlock__39jVu h2 {
  -webkit-align-self: center;
          align-self: center;
}

.DownBlocks_DownBlockHelp__2A7a3 h4 {
  -webkit-align-self: center;
          align-self: center;
  padding-top: 10px;
  color: var(--main-dark);
}

@media (max-width: 900px) {
  .DownBlocks_DownBlocks__3f9hk {
    -webkit-flex-flow: column;
            flex-flow: column;
  }
}

.AlbumNav_AlbumNav__2GItj {
  margin: 10px auto 0 auto;
  width: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-flow: row;
          flex-flow: row;
  padding: 3px;
  box-sizing: border-box;
  /* flex: 1; */
}

.AlbumNav_ArrAlbumNav__3Qbkr {
  padding: 10px;
  font-weight: bolder;
  color: var(--main-bright-green);
}

.AlbumNav_NumAlbumNav__3qNig {
  padding: 10px;
  font-weight: bolder;
  color: var(--main-dark);
}

.AlbumNav_Empty__1qwni {
  height: 65px;
}

.modal {
  z-index: 105;
  position: fixed;
  top: 17vh;
  left: 10%;
  width: 80%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
}

.modal__header {
  width: 100%;
  padding: 1rem 0.5rem;
  background: var(--main-dark);
  color: white;
}

.modal__header h2 {
  margin: 0.5rem;
}

.modal__header span {
  margin: 0.3rem;
  font-size: 1em;
  /* font-weight: bold; */
}

.modal__content {
  padding: 1rem 0.5rem;
}

.modal__footer {
  padding: 0.5rem 0.5rem;
}

@media (min-width: 768px) {
  .modal {
    top: 22vh;
    left: calc(50% - 20rem);
    width: 40rem;
  }
  .modal__header span {
    margin: 0.5rem;
    font-size: 1.2em;
    /* font-weight: bold; */
  }
}

.modal-enter {
  -webkit-transform: translateY(-10rem);
          transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  -webkit-transform: translateY(-10rem);
          transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}

.Map_MapContainer__1gA4_ {
  width: 100%;
  height: 100%;
}

.PhotoDescription_PhotoDescription__3HfiB li {
  margin-bottom: 3px;
  padding: 3px;
  border-bottom: 2px solid rgb(226, 225, 225);
}

.PhotoDescription_PhotoDescription__3HfiB li:hover {
  opacity: 0.8;
}

.PhotoDescription_PlaceItemModalContent__1AHTw {
  padding: 0;
}

.PhotoDescription_PlaceItemModalActions__1St-8 {
  text-align: right;
}

.PhotoDescription_MapContainer__2KGB8 {
  height: 19rem;
  width: 100%;
}

.PhotoDescription_SpanShowMap__2SdU0 {
  font-size: 1.2rem;
  font-weight: bold;
  /* color: #17a2b8; */
  color: var(--main-bright-green);

  cursor: pointer;
}

.PhotoDescription_UlShowMap__7uSyO {
  margin-top: 0.6rem;
  /* text-align: center; */
}

.PhotoDescription_SpanShowMap__2SdU0:hover {
  color: #128090;
}

.PhotoDescription_SpanCloseMap__mqwlp {
  font-size: 1.2rem;
  margin: 0 0 0.5rem 0;
  font-weight: bold;
  /* color: #17a2b8; */
  color: var(--main-bright-green);
  cursor: pointer;
}

.PhotoDescription_SpanCloseMap__mqwlp:hover {
  color: #128090;
}

@media (max-width: 699px) {
  .PhotoDescription_UlShowMap__7uSyO {
    text-align: center;
  }
}

.PhotoPreview_ViewerPreview__rICEd {
  margin: 0 auto 0 auto;
  width: 99%;
  max-width: 1057px;
  height: 600px;
  text-decoration: none;
  border: 5px solid #eee;
  padding: 2px 2px 2px 2px;
  overflow: hidden;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PhotoPreview_ViewerPreview__rICEd img {
  max-width: 100%;

  height: auto;
  max-height: 585px;
}

@media (max-width: 699px) {
  .PhotoPreview_ViewerPreview__rICEd {
    width: 100%;
    height: 400px;
  }

  .PhotoPreview_ViewerPreview__rICEd img {
    max-width: 100%;
    height: auto;
    max-height: 385px;
  }
}

.PhotoSwitchPreview_switchPreview__3h1B7 {
  display: -webkit-flex;
  display: flex;
  width: 99%;
  height: 2rem;
  padding-left: 5px;
}

.PhotoSwitchPreview_previewButton__1px8r {
  background-color: var(--main-bright-brown);
  width: 275px;
  height: 100%;
  cursor: pointer;
  border-radius: 5px 5px 0 0;
  padding: 5px 5px 5px 10px;
  /* font-weight: bold; */
  color: white;
  font-size: 1.1rem;
}

.PhotoSwitchPreview_previewButton__1px8r:hover {
  opacity: 0.8;
}

.PhotoSwitchPreview_previewEmpty__1SAUE {
  -webkit-flex: 1 1;
          flex: 1 1;
}

@media (max-width: 1200px) {
  .PhotoSwitchPreview_PhotoDescriptionBlock__1fdTU {
    -webkit-flex-flow: row;
            flex-flow: row;
    max-width: 1200px;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    padding-top: 5px;
  }

  .PhotoSwitchPreview_PhotoAlbDisplay__3sV8O {
    margin: 0 0 0 0;
  }

  .PhotoSwitchPreview_PhotoDisplay__3QCLh {
    -webkit-flex-flow: column;
            flex-flow: column;
  }
  .PhotoSwitchPreview_PhotoDescriptionDescr__3pUbT {
    max-width: 1000px;
  }

  .PhotoSwitchPreview_PhotoDescription__1cln7 {
    max-width: 1000px;
    border-top: none;
  }
  .PhotoSwitchPreview_PhotoDescriptionAlb__19dIh {
    width: 98%;
  }
  .PhotoSwitchPreview_PhotoDescriptionDescr__3pUbT span {
    text-align: left;
    font-size: 1.1rem;
    font-weight: bold;
  }
}

@media (max-width: 699px) {
  .PhotoSwitchPreview_switchPreview__3h1B7 {
    padding-left: 0;
  }
  .PhotoSwitchPreview_previewButton__1px8r {
    width: 250px;
  }
}

.PhotoCardCaption_PhotoCardCaption__1o8e_ {
  text-align: center;
  color: var(--main-dark);
  padding: 5px 0 5px 0;
  font-size: 0.9rem;
}

.PhotoCardCaption_PhotoCardCaption__1o8e_ p {
  text-align: center;
  color: white;
  padding: 3px 1px 3px 1px;
  width: 100%;
  /* background-color: Wheat; */
  background-color: var(--main-bright-brown);
  /* font-family: 'Roboto Condensed', sans-serif; */
}

.PhotoCardCaption_PhotoCardCaption__1o8e_ h3 {
  height: 80px;
  max-height: 80px;
}

.PhotoCard_PhotoCard__1EssT {
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* direction: column; */
  /* background-color: #f5f5f5; */
  /* background-color: #f5f5f5; */
  background-color: var(--main-light-sec);
  display: inline-block;
  /* margin: 20px 5px; */
  margin: 10px auto;
  width: 180px;
  height: 260px;
  box-shadow: 0 2px 3px #ccc;
  border: 1px solid #eee;
  padding: 5px 5px 5px 4px;
  box-sizing: border-box;
  border-radius: 5px;
}

.PhotoCard_PhotoCard__1EssT:hover {
  box-shadow: 0 2px 3px var(--main-bright-green);
}

.PhotoCard_ThumbContainer__3xpWl {
  height: 120px;
  width: 170px;
  overflow: hidden;
}

.PhotoCard_CardThumb__2sEIn {
  /* height: auto;
  width: 170px; */
  width: 170px;
  height: 120px;
  /* object-fit: scale-down; */
  object-fit: cover;
}

@media (min-width: 600px) {
  .PhotoCard_PhotoCard__1EssT {
    width: 200px;
  }
  .PhotoCard_ThumbContainer__3xpWl {
    height: 120px;
    width: 190px;
    overflow: hidden;
  }

  .PhotoCard_CardThumb__2sEIn {
    /* height: auto;
    width: 190px; */
    height: 120px;
    width: 190px;
    /* object-fit: scale-down; */
    object-fit: cover;
  }
}

.Slider_SliderBlock__2aaV3 {
  margin: 10px auto 65px auto;
  /* background-color: gainsboro; */
  background-color: rgba(83, 97, 98, 0.3);
  /* margin: 10px 5px 65px 5px; */
  width: 85%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  /* justify-content: center; */
  /* box-shadow: 0 2px 3px #ccc;
    border: 1px solid #eee; */
  padding: 2px;
  box-sizing: border-box;
  /* z-index: 99 !important; */
}

.Slider_SliderBlockAlb__1j_4C {
  margin: 10px auto 65px auto;
  background-color: rgba(83, 97, 98, 0.3);
  /* margin: 10px 5px 65px 5px; */
  width: 98%;
  /* display: flex; */
  /* flex-grow: 2; */

  padding: 2px;
  box-sizing: border-box;
}

.Slider_BlockTitle__3ZqeU {
  text-align: left;
  margin: 5px auto 5px auto;
  width: 80%;
  color: #364f6b;
  /* display: block; */
}

.Slider_SlideCard__UspWZ {
  width: auto;
  margin: 0 auto;
}

/* .SlideCardAlb {
  margin-right: auto;
  margin-left: 0;
} */

.Slider_AlbCaption__3GJmh {
  width: 98%;
  background-color: rgba(83, 97, 98, 0.1);
  padding: 5px 5px 5px 15px;
  margin-top: 10px;
}

.Slider_AlbCaption__3GJmh span {
  font-size: 1.5rem;
  text-align: left;
  /* color: var(--main-mid-dark); */
}

.Slider_AlbCaption__3GJmh span:hover {
  opacity: 0.8;
}

.Slider_SlideCardAlb__Z0pqJ img {
  /* height: auto;
  width: 170px; */
  /* width: 170px; */
  height: 170px;
  /* object-fit: scale-down; */

  object-fit: cover;
}

@media (max-width: 449px) {
  .Slider_SlideCard__UspWZ {
    margin-left: -15px;
  }

  .Slider_SliderBlock__2aaV3 {
    -webkit-justify-content: center;
            justify-content: center;
  }
}

/* @media (max-width: 599px) {
  .AlbCaption {
    display: none;
  }

  .SliderBlockAlb {
    display: none;
  }
} */

/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  border-radius: 4px;
}

.accordion__item + .accordion__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
  background-color: var(--main-light);
  color: #444;
  cursor: pointer;
  padding: 10px 10px 10px 10px;
  width: 100%;
  max-width: 1057px;
  text-align: left;
  border: none;
  outline: none;
}

.accordion__button:hover {
  background-color: var(--mid-dark);
}

.accordion__button:before {
  display: inline-block;
  content: '';
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

[hidden] {
  display: none;
}

.accordion__panel {
  padding: 10px 10px 10px 0;
  -webkit-animation: fadein 0.35s ease-in;
          animation: fadein 0.35s ease-in;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Form Part */
.btn {
  display: inline-block;
  background: var(--main-bright-brown);
  color: white;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  margin-top: 0.4rem;
  transition: opacity 0.2s ease-in;
  outline: none;
  margin: 1rem 0;
}

.btn:hover {
  opacity: 0.8;
}

.form-group {
  margin: 1.2rem 0;
}

.form {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

.form input[type='text'],
.form textarea {
  display: block;
  width: 100%;
  max-width: 900px;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
  outline: none;
}

.form input[type='submit'],
button {
  font: inherit;
}

.error {
  font-size: 1rem;
  padding: 5px;
  color: lightpink;
  /* display: inline-flex; */
}

.symbols {
  font-size: 1rem;
  padding: 5px;
  color: lightblue;
  /* display: inline-flex; */
}

.message-info {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-flow: row;
          flex-flow: row;
}

@media (max-width: 700px) {
  .message-info {
    -webkit-flex-flow: column;
            flex-flow: column;
    -webkit-align-items: start;
            align-items: start;
  }
  .error {
    padding: 3px;
  }
  .symbols {
    padding: 3px;
  }
}

/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap'); */

/* Global Styles */
:root {
  --primary-color: #17a2b8;
  --dark-color: #343a40;
  /* --light-color: #f4f4f4; */
  --light-color: gainsboro !important;
  --danger-color: #dc3545;
  --success-color: #28a745;
  --edit-color: orange;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  /* font-family: 'Open Sans', sans-serif; */
  /* font-size: 0.9rem; */
  /* line-height: 1.6;   */
  /* background-color: #fff; */
  color: #333;
}

a {
  color: #17a2b8;
  color: var(--primary-color);
  text-decoration: none;
}

ul {
  list-style: none;
}

/* img {
  width: 100%;
} */

/* Utilities */
.Admin_Container__1p5z7 {
  /* max-width: 1100px; */
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
  margin-top: 12rem;
  margin-bottom: 5rem;
}

.Admin_DashInnerContainer__mwLxZ {
  display: -webkit-flex;
  display: flex;

  margin: auto;
  overflow: hidden;
  padding: 0 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.Admin_DashInnerInfo__Gn3DT {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
}

.Admin_Select__3cf1c {
  font-size: 1.1rem;
}

/* Text Styles*/
.Admin_XLarge__1a9ut {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.Admin_Large__2srj3 {
  font-size: 2.5rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.Admin_Lead__3nSnK {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.Admin_TextCenter__1cMb_ {
  text-align: center;
}

.Admin_TextPrimary__1o_Yr {
  color: #17a2b8;
  color: var(--primary-color);
}

.Admin_TextDark__1D9Or {
  color: #343a40;
  color: var(--dark-color);
}

/* Padding */
.Admin_P__xCBs5 {
  padding: 0.5rem;
}
.Admin_P1__1VoUY {
  padding: 1rem;
}
.Admin_P2__3wwtv {
  padding: 2rem;
}
.Admin_P3__1xZtq {
  padding: 3rem;
}
.Admin_Py__2xZW7 {
  padding: 0.5rem 0;
}
.Admin_Py1__aSSv1 {
  padding: 1rem 0;
}
.Admin_Py2__3meGz {
  padding: 2rem 0;
}
.Admin_Py3__1j5Rg {
  padding: 3rem 0;
}

/* Margin */
.Admin_M__v1eCn {
  margin: 0.5rem;
}
.Admin_M1__1ougF {
  margin: 1rem;
}
.Admin_M2__leLFg {
  margin: 2rem;
}
.Admin_M3__U9fFD {
  margin: 3rem;
}
.Admin_My__1CmFS {
  margin: 0.5rem 0;
}
.Admin_My1__x3skW {
  margin: 1rem 0;
}
.Admin_My2__XwZRH {
  margin: 2rem 0;
}
.Admin_My3__2s3Up {
  margin: 3rem 0;
}

.Admin_WhiteLine__2ouDL {
  background-color: white;
  border-top: 1px solid #ddd;
  width: 100%;
  height: 125px;
  position: fixed;
  bottom: -10px;
  left: 0px;
  opacity: 0.9;
}

.Admin_Btn__X1Pb7 {
  display: inline-block;
  background: gainsboro;
  background: var(--light-color);
  color: #333;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  margin-top: 0.4rem;
  transition: opacity 0.2s ease-in;
  outline: none;
}

.Admin_BtnDash__2d1aR {
  width: 170px;
  display: inline-block;
  background: gainsboro;
  background: var(--light-color);
  color: #333;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  margin-top: 0.4rem;
  transition: opacity 0.2s ease-in;
  outline: none;
}

.Admin_BtnLine__3c6Ig {
  display: inline-block;
}

.Admin_BtnStat__2R-hn {
  min-width: 100px;
  cursor: auto;
}

.Admin_Badge__CTPda {
  font-size: 0.8rem;
  padding: 0.1rem;
  text-align: center;
  margin: 0.3rem;
  background: gainsboro;
  background: var(--light-color);
  color: #333;
}

.Admin_Alert__1gvLC {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: gainsboro;
  background: var(--light-color);
  color: #333;
}

.Admin_BtnPrimary__1mNdK,
.Admin_BgPrimary__3MY9f,
.Admin_BadgePrimary__3EfeM,
.Admin_AlertPrimary__1yy1d {
  background: #17a2b8;
  background: var(--primary-color);
  color: #fff;
}

.Admin_BtnLight__2pMUf,
.Admin_BgLight___B5xm,
.Admin_BadgeLight__sHN0Y,
.Admin_AlertLight__387Ya {
  background: gainsboro;
  background: var(--light-color);
  color: #333;
}

.Admin_BtnDark__1h16j,
.Admin_BgDark__1B-oD,
.Admin_BadgeDark__1hQrj,
.Admin_AlertDark__G94bZ {
  background: #343a40;
  background: var(--dark-color);
  color: #fff;
}

.Admin_BtnDanger__3ooK_,
.Admin_BgDanger__12Pu4,
.Admin_BadgeDanger__1Kr5R,
.Admin_AlertDanger__2y8Nv {
  background: #dc3545;
  background: var(--danger-color);
  color: #fff;
}

.Admin_BtnEdit__1ikcU {
  background: orange;
  background: var(--edit-color);
  color: #fff;
}

.Admin_BtnSuccess__1edMe,
.Admin_BgSuccess__qRIu_,
.Admin_BadgeSuccess__15Nq1,
.Admin_AlertSuccess__1XjE- {
  background: #28a745;
  background: var(--success-color);
  color: #fff;
}

.Admin_BtnWhite__2JkQU,
.Admin_BgWhite__26Vvq,
.Admin_BadgeWhite__Ze-ec,
.Admin_AlertWhite__1tVgY {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.Admin_Btn__X1Pb7:hover {
  opacity: 0.8;
}

.Admin_BtnDash__2d1aR:hover {
  opacity: 0.8;
}

.Admin_BgLight___B5xm,
.Admin_BadgeLight__sHN0Y {
  border: #ccc solid 1px;
}

.Admin_RoundImg__2LY9X {
  border-radius: 50%;
}

.Admin_Line__2UJiI {
  height: 1px;
  background: #ccc;
  margin: 1.5rem 0;
}

.Admin_DivLine__2ZXdF {
  border-top: 5px solid #17a2b8;
  /* border-radius: 3px; */
  margin: 1.5rem 0;
}

/* Upload Button*/

.Admin_UploadBtnWrapper__1z3uI {
  position: relative;
  overflow: hidden;
  display: inline-block;
  padding-top: 6px;
}

.Admin_UploadBtn__1n7hH {
  border: 2px solid #17a2b8;
  border: 2px solid var(--primary-color);
  color: white;
  background-color: #17a2b8;
  background-color: var(--primary-color);
  padding: 8px 20px;
  /* border-radius: 8px; */
  font-size: 20px;
  font-weight: bold;
}

.Admin_UploadBtnWrapper__1z3uI input[type='file'] {
  /* font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0; */
  display: none;
}

/* Overlay */
.Admin_DarkOverlay__V2r0r {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Forms */
.Admin_Form__QEYJL .Admin_FormGroup__2oFf_ {
  margin: 1.2rem 0;
}

.Admin_SecondLanguage__Fpb5R {
  outline: none;
  border: 5px solid lightgreen !important;
}

.Admin_Form__QEYJL .Admin_FormText__1sJO6 {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

.Admin_Form__QEYJL input[type='text'],
.Admin_Form__QEYJL input[type='email'],
.Admin_Form__QEYJL input[type='password'],
.Admin_Form__QEYJL input[type='date'],
.Admin_Form__QEYJL select,
.Admin_Form__QEYJL textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
}

.Admin_Form__QEYJL input[type='submit'],
button {
  font: inherit;
}

.Admin_Table__2dYSv th,
.Admin_Table__2dYSv td {
  padding: 1rem;
  text-align: left;
}

.Admin_Table__2dYSv th {
  background: gainsboro;
  background: var(--light-color);
}

.Admin_Table__2dYSv td:hover {
  background: gainsboro;
  background: var(--light-color);
}

/* Navbar */
.Admin_Navbar__3zK0o {
  box-sizing: border-box;

  display: -webkit-flex;

  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.7rem 2rem;
  position: fixed;
  z-index: 101;
  width: 100%;
  top: 75px;
  border-bottom: solid 1px #17a2b8;
  border-bottom: solid 1px var(--primary-color);
  opacity: 0.9;
}

.Admin_Navbar__3zK0o ul {
  display: -webkit-flex;
  display: flex;
}

.Admin_Navbar__3zK0o a {
  color: #fff;
  padding: 0.45rem;
  margin: 0 0.25rem;
}

.Admin_Navbar__3zK0o a:hover {
  color: #17a2b8;
  color: var(--primary-color);
}

.Admin_Navbar__3zK0o .Admin_Welcome__1ux41 span {
  margin-right: 0.6rem;
}

.Admin_DashNav__2glIf {
  min-width: 200px;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  /* align: left; */
  -webkit-align-items: left;
          align-items: left;
  height: 100%;
  border: 1px solid #eee;
}

/*  */

.Admin_ThumbContainer__1r4X5 {
  height: 75px;
  width: 100px;
  overflow: hidden;
}

.Admin_Thumb__1hDIP {
  width: 100px;
  height: auto;
}

.Admin_Statistic__OoSx9 {
  /* margin: 10px; */
  padding-left: 50px;
}

.Admin_Statistic__OoSx9 a,
h1 {
  color: #364f6b;
}

.Admin_Statistic__OoSx9 a:hover {
  color: #40a4c8;
}

.Admin_Rules__3KwAL h5 {
  padding: 5px;
  text-align: left;
  background-color: yellowgreen;
}

/* Messages */
.Admin_Message__3bBAU {
  max-width: 1000px;
}

.Admin_Date__1vmbl {
  margin: 1.2rem 0;
  padding: 0.3rem;
  background-color: lightsteelblue;
}

.Admin_Date__1vmbl span {
  padding: 1rem;
  font-weight: bold;
}

.Admin_Email__20JI6 {
  margin: 1.2rem 0;
  padding: 0.3rem;
  background-color: lightsteelblue;
}

.Admin_Email__20JI6 span {
  padding: 1rem;
  font-weight: bold;
}

.Admin_MessageBody__1X9-R {
  margin: 1.2rem 0;
  padding: 0.3rem;
  background-color: lightyellow;
}

.Admin_MessageBody__1X9-R span {
  padding: 1.2rem;
  font-size: 1.2rem;
}

/* Mobile Styles */
@media (max-width: 700px) {
  .Admin_Container__1p5z7 {
    margin-top: 11rem;
  }

  .Admin_HideSm__cOmL0 {
    display: none;
  }

  /* Text Styles */
  .Admin_XLarge__1a9ut {
    font-size: 3rem;
  }

  .Admin_Large__2srj3 {
    font-size: 2rem;
  }

  .Admin_Lead__3nSnK {
    font-size: 1rem;
  }

  /* Navbar */
  .Admin_Navbar__3zK0o {
    /* box-sizing: border-box;
    margin: 0;
    padding: 0; */
    display: block;
    text-align: center;
  }

  .Admin_Navbar__3zK0o ul {
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .Admin_Navbar__3zK0o h1 {
    margin-bottom: 1rem;
  }

  .Admin_Navbar__3zK0o .Admin_Welcome__1ux41 {
    display: none;
  }

  .Admin_DashButtons__5Jsrt a {
    display: block;
    width: 100%;
    margin-bottom: 0.2rem;
  }

  /* .DashInnerContainer {
    flex-direction: column;
  } */
}

:root {
  --primary-color: #17a2b8;
  --dark-color: #343a40;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
}

.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: #f4f4f4;
  background: var(--light-color);
  color: #333;
}

.alert-primary {
  background: #17a2b8;
  background: var(--primary-color);
  color: #fff;
}

.alert-light {
  background: #f4f4f4;
  background: var(--light-color);
  color: #333;
}

.alert-dark {
  background: #343a40;
  background: var(--dark-color);
  color: #fff;
}

.alert-danger {
  background: #dc3545;
  background: var(--danger-color);
  color: #fff;
}

.alert-success {
  background: #28a745;
  background: var(--success-color);
  color: #fff;
}

.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

