.PhotoDescription li {
  margin-bottom: 3px;
  padding: 3px;
  border-bottom: 2px solid rgb(226, 225, 225);
}

.PhotoDescription li:hover {
  opacity: 0.8;
}

.PlaceItemModalContent {
  padding: 0;
}

.PlaceItemModalActions {
  text-align: right;
}

.MapContainer {
  height: 19rem;
  width: 100%;
}

.SpanShowMap {
  font-size: 1.2rem;
  font-weight: bold;
  /* color: #17a2b8; */
  color: var(--main-bright-green);

  cursor: pointer;
}

.UlShowMap {
  margin-top: 0.6rem;
  /* text-align: center; */
}

.SpanShowMap:hover {
  color: #128090;
}

.SpanCloseMap {
  font-size: 1.2rem;
  margin: 0 0 0.5rem 0;
  font-weight: bold;
  /* color: #17a2b8; */
  color: var(--main-bright-green);
  cursor: pointer;
}

.SpanCloseMap:hover {
  color: #128090;
}

@media (max-width: 699px) {
  .UlShowMap {
    text-align: center;
  }
}
