.switchPreview {
  display: flex;
  width: 99%;
  height: 2rem;
  padding-left: 5px;
}

.previewButton {
  background-color: var(--main-bright-brown);
  width: 275px;
  height: 100%;
  cursor: pointer;
  border-radius: 5px 5px 0 0;
  padding: 5px 5px 5px 10px;
  /* font-weight: bold; */
  color: white;
  font-size: 1.1rem;
}

.previewButton:hover {
  opacity: 0.8;
}

.previewEmpty {
  flex: 1;
}

@media (max-width: 1200px) {
  .PhotoDescriptionBlock {
    flex-flow: row;
    max-width: 1200px;
    min-height: fit-content;
    padding-top: 5px;
  }

  .PhotoAlbDisplay {
    margin: 0 0 0 0;
  }

  .PhotoDisplay {
    flex-flow: column;
  }
  .PhotoDescriptionDescr {
    max-width: 1000px;
  }

  .PhotoDescription {
    max-width: 1000px;
    border-top: none;
  }
  .PhotoDescriptionAlb {
    width: 98%;
  }
  .PhotoDescriptionDescr span {
    text-align: left;
    font-size: 1.1rem;
    font-weight: bold;
  }
}

@media (max-width: 699px) {
  .switchPreview {
    padding-left: 0;
  }
  .previewButton {
    width: 250px;
  }
}
