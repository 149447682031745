.Container {
  margin: 85px auto 60px auto;
  width: 95%;
  align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

.GalleryImage {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
/* .CatCards {
  display: block;
} */

.ContainerAll {
  width: fit-content;
  margin: 20px auto;
  height: auto;
}

.Container hr {
  background-image: linear-gradient(
    to bottom right,
    var(--main-dark),
    var(--main-light)
  );
  height: 3px;
  border: none;
  margin: 10px auto 10px auto;
  width: 85%;
}

@media (max-width: 699px) {
  .Container {
    margin: 70px auto 40px auto;
  }
  .GalleryImage {
    padding: 1px;
  }
}
