.PhotoDownload {
  display: flex;
  /* display: inline-block; */
  flex-flow: row;
  margin: 1px auto 10px auto;
  width: 100%;
  /* padding: 15px; */
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.IconPhotoDownload {
  /* display: block; */
  color: var(--main-bright-green);
  display: flex;
  align-items: center;
  padding-right: 15px;
}

.PhotoDownload h3 {
  /* display: block; */
  padding: 1px;
  display: flex;
  align-items: center;
}
