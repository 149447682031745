.SliderBlock {
  margin: 10px auto 65px auto;
  /* background-color: gainsboro; */
  background-color: rgba(83, 97, 98, 0.3);
  /* margin: 10px 5px 65px 5px; */
  width: 85%;
  justify-content: space-between;
  align-items: center;
  /* justify-content: center; */
  /* box-shadow: 0 2px 3px #ccc;
    border: 1px solid #eee; */
  padding: 2px;
  box-sizing: border-box;
  /* z-index: 99 !important; */
}

.SliderBlockAlb {
  margin: 10px auto 65px auto;
  background-color: rgba(83, 97, 98, 0.3);
  /* margin: 10px 5px 65px 5px; */
  width: 98%;
  /* display: flex; */
  /* flex-grow: 2; */

  padding: 2px;
  box-sizing: border-box;
}

.BlockTitle {
  text-align: left;
  margin: 5px auto 5px auto;
  width: 80%;
  color: #364f6b;
  /* display: block; */
}

.SlideCard {
  width: auto;
  margin: 0 auto;
}

/* .SlideCardAlb {
  margin-right: auto;
  margin-left: 0;
} */

.AlbCaption {
  width: 98%;
  background-color: rgba(83, 97, 98, 0.1);
  padding: 5px 5px 5px 15px;
  margin-top: 10px;
}

.AlbCaption span {
  font-size: 1.5rem;
  text-align: left;
  /* color: var(--main-mid-dark); */
}

.AlbCaption span:hover {
  opacity: 0.8;
}

.SlideCardAlb img {
  /* height: auto;
  width: 170px; */
  /* width: 170px; */
  height: 170px;
  /* object-fit: scale-down; */

  object-fit: cover;
}

@media (max-width: 449px) {
  .SlideCard {
    margin-left: -15px;
  }

  .SliderBlock {
    justify-content: center;
  }
}

/* @media (max-width: 599px) {
  .AlbCaption {
    display: none;
  }

  .SliderBlockAlb {
    display: none;
  }
} */
