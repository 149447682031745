.AlbumInfo {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  max-width: 1200px;
  border-bottom: 1px solid rgb(226, 225, 225);
  padding: 15px;
  box-sizing: border-box;
}

.AlbumInfo span {
  text-align: left;
  font-size: 1rem;
}

.AlbumInfo a {
  outline: none;
}

@media (max-width: 699px) {
  .AlbumInfo span {
    font-size: 0.7rem;
  }
  .AlbumInfo {
    padding: 5px;
  }
}
