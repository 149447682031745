.Terms {
  margin: 10px auto 10px auto;
  max-width: 1600px;
  width: 90%;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  align-items: center;
  justify-content: space-between;
  padding: 0.9em;
}

.Container {
  margin: 15px auto 40px auto;
  width: 95%;
  align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

.Terms span {
  text-align: left;
  font-size: 1.3rem;
}
