.Description {
  margin: 25px auto 20px auto;
  width: 85%;
  align-items: center;
  padding: 2px;
  box-sizing: border-box;
  text-align: right;
}

.Description span {
  margin: 5px auto 15px auto;
  width: 100%;
  color: var(--main-dark);
  font-size: 2em;
  font-weight: bold;
}

.Description hr {
  background-image: linear-gradient(to bottom right, #364f6b);
  height: 3px;
  border: none;
  width: 100% !important;
}

@media (max-width: 699px) {
  .Description {
    margin: 5px auto 20px auto;
  }
  .Description span {
    font-size: 1.1em;
  }
}
