.Latest {
  margin: 10px auto 70px auto;
  max-width: 1600px;
  width: 90%;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: left;
  padding: 0.9em;
}

.Container {
  margin: 15px auto 40px auto;
  width: 95%;
  align-items: center;
  justify-content: center;
  padding: 2px;
  box-sizing: border-box;
}

.SlideCard {
  padding: 10px;
}

@media (max-width: 699px) {
  .Latest {
    margin: 10px auto 50px auto;
    justify-content: center;
  }
}
