.Statistics {
  width: 100%;
  justify-content: center;
  text-align: center;
  margin: 10px;
  /* max-width: 1100px; */
  /* background-color: #e1eaf5; */
}

h4 {
  color: #364f6b;
}

.Statistics a {
  color: #364f6b;
}

.Statistics a:hover {
  color: #40a4c8;
}

.Statistics span {
  display: inline-block;
  padding: 10px;
}

.Statistics h4 {
  display: inline-block;
  /* padding: 10px; */
}

@media (max-width: 599px) {
  .Statistics span {
    padding: 3px;
  }
  .Statistics {
    margin: 3px 0 30px 0;
  }
}
