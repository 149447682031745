.PhotoCard {
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* direction: column; */
  /* background-color: #f5f5f5; */
  /* background-color: #f5f5f5; */
  background-color: var(--main-light-sec);
  display: inline-block;
  /* margin: 20px 5px; */
  margin: 10px auto;
  width: 180px;
  height: 260px;
  box-shadow: 0 2px 3px #ccc;
  border: 1px solid #eee;
  padding: 5px 5px 5px 4px;
  box-sizing: border-box;
  border-radius: 5px;
}

.PhotoCard:hover {
  box-shadow: 0 2px 3px var(--main-bright-green);
}

.ThumbContainer {
  height: 120px;
  width: 170px;
  overflow: hidden;
}

.CardThumb {
  /* height: auto;
  width: 170px; */
  width: 170px;
  height: 120px;
  /* object-fit: scale-down; */
  object-fit: cover;
}

@media (min-width: 600px) {
  .PhotoCard {
    width: 200px;
  }
  .ThumbContainer {
    height: 120px;
    width: 190px;
    overflow: hidden;
  }

  .CardThumb {
    /* height: auto;
    width: 190px; */
    height: 120px;
    width: 190px;
    /* object-fit: scale-down; */
    object-fit: cover;
  }
}
