@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100&display=swap');

:root {
  --main-light: #f3f4ed;
  --main-dark: #424642;
  --main-mid-dark: #536162;
  --main-bright-brown: #c06014;
  --main-light-sec: #f4f9f9;
  --main-bright-orange: #ffc478;
  --main-bright-red: #eb5e0b;
  --main-bright-green: #5eaaa8;
  --main-bright-green-light: #a3d2ca;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  /* font-family: 'Roboto', sans-serif; */
  /* font-family: 'PT Sans', sans-serif; */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f5f5f5; */
  background-color: var(--main-light);
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slick-prev:before,
.slick-next:before {
  color: var(--main-dark) !important;
}

/* .slick-slide {
  padding: 0px 65px !important;
} */

.slick-next {
  right: -25px !important;
}
.slick-prev {
  left: -25px !important;
}

.slick-track {
  /* margin: 0 auto !important; */
  display: flex !important;
  margin-left: 1px !important;
  margin-right: 1px !important;
}

/* the slides */
.slick-slide {
  /* float: left !important; */
  margin: 0px 15px !important;
  width: 200px !important;
}
/* the parent */
/* .slick-list {
  overflow: hidden !important;
} */

/* .alice-carousel__wrapper {
  width: 98% !important;
} */

progress {
  vertical-align: baseline;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

/* progress - styles for progress-bar in photo upload */

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #28a745;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}
