.Toolbar {
  height: 70px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  /* background-color: #96a3b1; */
  background-color: var(--main-dark);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 90;
}

.Toolbar nav {
  height: 100%;
}

.Logo {
  height: 80%;
}

@media (max-width: 780px) {
  .DesktopOnly {
    display: none;
  }
  .Toolbar {
    height: 56px;
  }
}
