/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@500&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.PageTitle {
  margin: 0px auto 20px auto;
  width: 85%;
  align-items: center;
  padding: 2px;
  box-sizing: border-box;
  text-align: right;
  display: flex;
  flex-flow: column;
}

.PageTitleSpan {
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Montserrat', sans-serif;
  /* font-family: 'Comfortaa', cursive; */
  margin: 5px 0px 5px 0px;
  width: 100%;
  color: var(--main-dark);
  font-size: 2em;
  font-weight: bold;
}

.AddInfoSpan {
  margin: 3px 0px 3px 0px;
  width: 100%;
  color: var(--main-dark);
  font-size: 1.2em;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
}

.PageTitle hr {
  background-image: linear-gradient(to bottom right, var(--main-dark));
  height: 3px;
  border: none;
  margin-top: 3px;
  width: 100%;
}

@media (max-width: 699px) {
  .PageTitleSpan {
    font-size: 1.2em;
  }
  .AddInfoSpan {
    font-size: 1em;
  }
}
