@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');

.PhotoCardCaption {
  text-align: center;
  color: var(--main-dark);
  padding: 5px 0 5px 0;
  font-size: 0.9rem;
}

.PhotoCardCaption p {
  text-align: center;
  color: white;
  padding: 3px 1px 3px 1px;
  width: 100%;
  /* background-color: Wheat; */
  background-color: var(--main-bright-brown);
  /* font-family: 'Roboto Condensed', sans-serif; */
}

.PhotoCardCaption h3 {
  height: 80px;
  max-height: 80px;
}
