.modal {
  z-index: 105;
  position: fixed;
  top: 17vh;
  left: 10%;
  width: 80%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
}

.modal__header {
  width: 100%;
  padding: 1rem 0.5rem;
  background: var(--main-dark);
  color: white;
}

.modal__header h2 {
  margin: 0.5rem;
}

.modal__header span {
  margin: 0.3rem;
  font-size: 1em;
  /* font-weight: bold; */
}

.modal__content {
  padding: 1rem 0.5rem;
}

.modal__footer {
  padding: 0.5rem 0.5rem;
}

@media (min-width: 768px) {
  .modal {
    top: 22vh;
    left: calc(50% - 20rem);
    width: 40rem;
  }
  .modal__header span {
    margin: 0.5rem;
    font-size: 1.2em;
    /* font-weight: bold; */
  }
}

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}
