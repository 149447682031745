.ShowCards {
  margin: 10px auto 10px auto;
  min-height: 100vh;
  max-width: 1600px;
  width: 90%;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  justify-content: left;
  padding: 0.9em;
}

.Container {
  margin: 15px auto 40px auto;
  width: 95%;
  align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

.SlideCard {
  padding: 10px;
  /* flex-grow: 1; */
}

@media (max-width: 699px) {
  .ShowCards {
    justify-content: center;
  }
}
