.MiniSiteMap {
  width: 100%;
  position: fixed;
  top: 73px;
  left: 0;
  background-color: var(--main-mid-dark);
  display: flex;
  justify-content: left;
  padding: 0 40px;
  margin: -3px 0 15px 0;
  box-sizing: border-box;
  z-index: 90;
  border-top: 7px solid rgba(255, 255, 255, 0.8);
  background-clip: padding-box;
}

.MapElement {
  display: inline-block;
  padding: 5px 10px 5px 10px;
  color: white;
}

.MapElementBack {
  /* background-color: #b6b8b9; */
  display: inline-block;
  padding: 5px 0px 0px 0px;
  height: 100%;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.MapElementBack span {
  /* background-color: #b6b8b9; */

  color: white;
  height: 100%;
  padding: 0 10px 3px 0;
}

.MapElementBack:hover {
  border-bottom: 2px solid var(--main-bright-green);
}

.MapElement a {
  /* color: white; */
  color: white;
  height: 100%;
  padding-bottom: 3px;
  border-bottom: 2px solid transparent;
  text-decoration: none;
}

.MapElement a:hover,
.MapElement a:active,
.MapElement a.active {
  border-bottom: 2px solid #40a4c8;
}

@media (max-width: 699px) {
  .MiniSiteMap {
    display: none;
    /* flex-flow: column; */
  }
}

/* @media (max-width: 500px) {
  .MiniSiteMap {
    display: none;
    flex-flow: column;
  }
  .MapElement {
    padding: 1px 0px 1px 0px;
  }
} */
