@import url('https://fonts.googleapis.com/css?family=Open+Sans');

* {
  box-sizing: border-box;
}

.Card {
  margin: 0.9em;
  max-width: 145em;
  overflow: hidden;
  border-bottom: 1px solid rgb(226, 225, 225);
}

.Card:hover {
  border-bottom: 1px solid var(--main-bright-green);
}

.CardBody {
  width: 22em;
  max-height: 100%;
  padding: 0.8em;
}

.CardText {
  margin: 0.8em 0 0.8em 0;
  padding-left: 0.8em;
}

.CardText h2,
h3,
p {
  margin-top: 0.5rem;
}

.CardText h2 {
  margin-bottom: 0.5rem;
}

a {
  color: var(--main-bright-green);
  text-decoration: none;
}

.CardImage {
  height: 100%;
  max-height: 12em;
  width: 250px;
  object-fit: cover;
  border-radius: 5px;
}

@media (max-width: 1100px) {
  .Card {
    margin: 0.78em;
  }
  .CardText h2,
  h3,
  p {
    margin-top: 0;
    font-size: 0.9em;
  }
  .CardText {
    margin: 0;
  }
}

@media (max-width: 870px) {
  .Card {
    max-width: 100%;
  }
  .CardText {
    margin: 0;
  }
}

@media (max-width: 799px) {
  .Card {
    margin: 0.75em 1.1em;
    /* height: 10em; */
  }
  .Image {
    max-width: 40%;
  }

  .Total {
    font-size: 0.8em;
  }
  .CardText {
    margin: 0;
  }
}
