.Contributors {
  max-width: 1600px;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  padding: 0.9em;
  margin: 0 auto 0 auto;
  width: 87%;
}

.BlockTitle {
  text-align: left;
  width: 80%;
  color: var(--main-mid-dark);
  /* display: block; */
}

.Container {
  margin: 95px auto 60px auto;
  width: 95%;
  align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

@media (max-width: 699px) {
  .Contributors {
    flex-flow: column nowrap;
    padding: 0;
  }

  .Container {
    margin: 70px auto 60px auto;
  }
}
