.Albums {
  margin: 0 auto 60px auto;
  max-width: 1000px;
  display: flex;
  flex-flow: column;
  align-content: flex-start;
  justify-content: space-between;
  padding: 0.9em;
}

.Albums a {
  color: var(--main-mid-dark);
}

.Albums a:hover {
  color: var(--main-bright-green);
}
.Container {
  margin: 95px auto 60px auto;
  width: 95%;
  align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

@media (max-width: 699px) {
  .Container {
    margin: 70px auto 40px auto;
  }
}
