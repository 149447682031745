.LangBar {
  display: flex;
}
.LanguageSwitch {
  margin: 10px 0;
  /* margin-left: 15px; */
  box-sizing: border-box;
  display: block;
  width: 100%;
  /* font-size: 1.1rem; */
}

.LanguageButton {
  color: white;
  background-color: var(--main-mid-dark);
  /* background-color: #b6b8b9; */
  border: none;
  width: 45px;
  box-sizing: border-box;
  display: block;
  cursor: pointer;
  /* margin: 2px; */
  outline: none;
}

.LanguageButtonActive {
  color: white;
  background-color: var(--main-dark);
  border: none;
  width: 45px;
  box-sizing: border-box;
  display: block;
  cursor: pointer;
  /* margin: 2px; */
}

.LanguageButtonActive:focus {
  outline: 0;
}

/* .LanguageButton:hover,
.LanguageButton:active,
.LanguageButton.active {
  background-color: var(--main-bright-green);
} */

@media (min-width: 780px) {
  .LanguageSwitch {
    margin: 0;
    display: flex;
    align-items: center;
    width: auto;
    height: 70px;
  }

  .LanguageSwitchActive {
    color: white;
    height: 70px;
    padding: 17px 10px 29px 10px;
    box-sizing: border-box;
    display: block;
    width: 100%;
    font-size: 1.1rem;
  }

  .LanguageButton {
    color: white;
    height: 70px;
    padding: 17px 10px 20px 10px;
    font-size: 1.1rem;
    cursor: pointer;
  }

  .LanguageButtonActive {
    background-color: var(--main-bright-green);
    height: 70px;
    padding: 17px 10px 20px 10px;
    font-size: 1.1rem;
    /* font-weight: bolder; */
    cursor: pointer;
  }

  /* .LanguageButton:hover,
  .LanguageButton:active,
  .LanguageButton.active {
    font-size: 1.2rem;

    color: white;
  } */

  .LanguageButtonActive:focus {
    outline: 0;
  }
}
