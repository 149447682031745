.Contributor {
  margin: 0 auto 70px auto;
  max-width: 1600px;
  width: 90%;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  justify-content: left;
  padding: 0.9em;
}

.Container {
  margin: 95px auto 40px auto;
  width: 95%;
  align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

.SlideCard {
  padding: 10px;
}

@media (max-width: 699px) {
  .Contributor {
    margin: 0 auto 50px auto;
    justify-content: center;
  }
  .Container {
    margin: 70px auto 40px auto;
  }
}
