.Pagination {
  margin: 15px auto 65px auto;
  width: 90%;
  justify-content: center;
  padding: 2px;
  box-sizing: border-box;
  height: 45px;
  display: flex;
  position: fixed;
  bottom: -10px;
  left: 0px;
  width: 100%;
  z-index: 1;
  /* background-color: white; */
}

.WhiteLine {
  background-color: white;
  border-top: 1px solid #ddd;
  width: 100%;
  height: 125px;
  position: fixed;
  bottom: -10px;
  left: 0px;
  opacity: 0.9;
}

.Pagination span {
  background-color: white;
  height: auto;
  cursor: pointer;
  color: black;
  width: auto;
  flex-flow: row;
  padding: 10px 16px 10px 16px;
  text-decoration: none;
  transition: 0.2s ease-in;
  border: 1px solid #ddd;
}

.Pagination a {
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.Pagination span.active {
  /* background-color: #40a4c8; */
  background-color: var(--main-bright-green);
  color: white;
  /* border: 1px solid #40a4c8; */
  border: 1px solid var(--main-bright-green);
}

@media (max-width: 499px) {
  .Pagination {
    margin: 15px auto 50px auto;
  }
  .WhiteLine {
    height: 115px;
  }
}

/* @media (max-width: 350px) {
  .Pagination span {
    flex-flow: column;
  }
} */
