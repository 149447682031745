.NavigationItem {
  margin: 10px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;

  /* padding-bottom: 16px !important; */
}

.NavigationItem a {
  color: var(--main-bright-green);
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
  outline: none;
}

@media (min-width: 780px) {
  .NavigationItem {
    margin: 0;
    display: flex;
    align-items: center;
    width: auto;
    height: 70px;
  }

  .NavigationItem a {
    color: var(--main-light);
    height: 70px;
    padding: 25px 10px;
    border-bottom: 4px solid transparent;
  }

  .NavigationItem a:hover,
  .NavigationItem a:active,
  .NavigationItem a.active {
    background-color: var(--main-dark);
    border-bottom: 4px solid var(--main-bright-green);
    color: white;
  }
}
