/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  border-radius: 4px;
}

.accordion__item + .accordion__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
  background-color: var(--main-light);
  color: #444;
  cursor: pointer;
  padding: 10px 10px 10px 10px;
  width: 100%;
  max-width: 1057px;
  text-align: left;
  border: none;
  outline: none;
}

.accordion__button:hover {
  background-color: var(--mid-dark);
}

.accordion__button:before {
  display: inline-block;
  content: '';
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  transform: rotate(45deg);
}

[hidden] {
  display: none;
}

.accordion__panel {
  padding: 10px 10px 10px 0;
  animation: fadein 0.35s ease-in;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Form Part */
.btn {
  display: inline-block;
  background: var(--main-bright-brown);
  color: white;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  margin-top: 0.4rem;
  transition: opacity 0.2s ease-in;
  outline: none;
  margin: 1rem 0;
}

.btn:hover {
  opacity: 0.8;
}

.form-group {
  margin: 1.2rem 0;
}

.form {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

.form input[type='text'],
.form textarea {
  display: block;
  width: 100%;
  max-width: 900px;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
  outline: none;
}

.form input[type='submit'],
button {
  font: inherit;
}

.error {
  font-size: 1rem;
  padding: 5px;
  color: lightpink;
  /* display: inline-flex; */
}

.symbols {
  font-size: 1rem;
  padding: 5px;
  color: lightblue;
  /* display: inline-flex; */
}

.message-info {
  display: flex;
  align-items: center;
  flex-flow: row;
}

@media (max-width: 700px) {
  .message-info {
    flex-flow: column;
    align-items: start;
  }
  .error {
    padding: 3px;
  }
  .symbols {
    padding: 3px;
  }
}
