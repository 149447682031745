:root {
  --primary-color: #17a2b8;
  --dark-color: #343a40;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
}

.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.alert-light {
  background: var(--light-color);
  color: #333;
}

.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.alert-success {
  background: var(--success-color);
  color: #fff;
}

.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}
