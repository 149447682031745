.Locations {
  max-width: 1600px;
  width: 84%;
  display: flex;
  flex-flow: column;
  align-content: flex-start;
  padding: 0.9em;
  margin: 0px auto 0px auto;
}

.Container {
  margin: 95px auto 60px auto;
  width: 95%;
  align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

.SearchBar {
  width: 80%;
  max-width: 800px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 60px auto 25px auto;
  border: 3px solid var(--main-bright-green);
  padding: 5px;
  height: 36px;
  border-radius: 5px;
  outline: none;
  box-sizing: border-box;
}

.SearchTerm:focus {
  color: var(--main-bright-green);
}

@media (max-width: 699px) {
  .Locations {
    width: 95%;
  }
  .Container {
    margin: 70px auto 60px auto;
  }
  .SearchBar {
    margin: 20px auto 15px auto;
  }
}
