.Periods {
  max-width: 1000px;
  width: 87%;
  display: flex;
  flex-flow: column;
  align-content: flex-start;
  padding: 0.9em;
  margin: 0px auto 0px auto;
}

.Periods a {
  color: var(--main-mid-dark);
}

.Periods a:hover {
  color: var(--main-bright-green);
}

.Container {
  margin: 95px auto 60px auto;
  width: 95%;
  align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

@media (max-width: 699px) {
  .Container {
    margin: 70px auto 40px auto;
  }
}
