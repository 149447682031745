.AlbumNav {
  margin: 10px auto 0 auto;
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-flow: row;
  padding: 3px;
  box-sizing: border-box;
  /* flex: 1; */
}

.ArrAlbumNav {
  padding: 10px;
  font-weight: bolder;
  color: var(--main-bright-green);
}

.NumAlbumNav {
  padding: 10px;
  font-weight: bolder;
  color: var(--main-dark);
}

.Empty {
  height: 65px;
}
