.SearchBar {
  width: 80%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 60px auto 25px auto;
  /* box-shadow: 0 2px 3px #ccc;
  border: 1px solid #eee; */
  padding: 10px;
  box-sizing: border-box;
}

.SearchTerm {
  width: 70%;
  border: 3px solid var(--main-bright-green);
  border-right: none;
  padding: 5px;
  height: 36px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9dbfaf;
}

.SearchTerm:focus {
  color: var(--main-bright-green);
}

.SearchButton {
  width: 40px;
  height: 36px;
  border: 1px solid var(--main-bright-green);
  background: var(--main-bright-green);
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}

@media (max-width: 699px) {
  .SearchBar {
    width: 95%;
    margin: 30px auto 25px auto;
  }
  .SearchTerm {
    width: 80%;
  }
}
