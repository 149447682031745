.ViewerPreview {
  margin: 0 auto 0 auto;
  width: 99%;
  max-width: 1057px;
  height: 600px;
  text-decoration: none;
  border: 5px solid #eee;
  padding: 2px 2px 2px 2px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ViewerPreview img {
  max-width: 100%;

  height: auto;
  max-height: 585px;
}

@media (max-width: 699px) {
  .ViewerPreview {
    width: 100%;
    height: 400px;
  }

  .ViewerPreview img {
    max-width: 100%;
    height: auto;
    max-height: 385px;
  }
}
