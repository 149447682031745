.Period {
  margin: 0 auto 70px auto;
  max-width: 1600px;
  width: 90%;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  justify-content: left;
  padding: 0.9em;
}

.Dropdown {
  margin: 0 auto 0 auto;
  max-width: 1600px;
  width: 90%;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  justify-content: left;
  padding: 0.2em;
}

.DropdownPanel {
  margin: 0 auto 0 auto;
  width: 90%;
  background-color: #f5f5f5;
  display: flex;
  justify-content: left;
  padding: 0 20px;
  /* margin: 5px 0 5px 0; */
  box-sizing: border-box;
  z-index: 90;
}

.Container {
  margin: 95px auto 40px auto;
  width: 95%;
  align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

.Container h2 {
  padding: 40px;
}

.SlideCard {
  padding: 10px;
}

@media (max-width: 699px) {
  .Period {
    margin: 0 auto 50px auto;
    justify-content: center;
  }
  .DropdownPanel {
    padding: 0;
    width: 100%;
  }
  .Dropdown {
    margin: 0 auto;
  }
  .Container {
    margin: 70px auto 40px auto;
  }
}
