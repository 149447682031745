.Category {
  max-width: 1600px;
  display: flex;
  flex-flow: row wrap;
  /* align-items: flex-start; */
  justify-content: center;
  padding: 0.9em;
  margin: 0 auto 0 auto;
  width: 85%;
}

.Container {
  margin: 95px auto 60px auto;
  width: 95%;
  align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

@media (max-width: 699px) {
  .Category {
    flex-flow: column nowrap;
    padding: 0;
    align-items: center;
  }

  .Container {
    margin: 70px auto 60px auto;
  }
}
