.Container {
  margin: 10px auto 70px auto;
  width: 95%;
  align-items: center;
  padding: 2px;
  box-sizing: border-box;
}

.PhotoPage {
  margin: 0 auto 0 auto;
  max-width: 1500px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2px 5px 10px 5px;
  box-sizing: border-box;
}

.PhotoDisplay {
  margin: 0 auto 0 auto;
  align-items: flex-start;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0 5px 10px 5px;
  box-sizing: border-box;
}

.PhotoAlbDisplay {
  /* margin: 0 auto 0 auto; */
  margin: 0 0 0 -8px;
  width: 100%;
  flex: 1;
}

.Viewer {
  margin: 0 auto 0 auto;
  width: 99%;
  height: 600px;
  text-decoration: none;
  border: 5px solid #eee;
  padding: 2px 2px 2px 2px;
  flex: 1;
}

.ViewerHidden {
  display: none;
}

.ViewerSpinner {
  margin: 0 auto 0 auto;
  width: 99%;
  height: 600px;
  text-decoration: none;
  padding: 2px 2px 2px 2px;
  flex: 1;
}

.Caption {
  text-align: center;
  margin: 65px auto 15px auto;
  width: 80%;
  min-height: 70px;
}

.Caption span {
  color: var(--main-dark);
  font-size: 2em;
  font-weight: bold;
  text-align: center;
}

.PhotoContainer {
  display: inline-block;
  margin: 0 0 0 0;
  width: 100%;
  box-shadow: 0 2px 3px #ccc;
  padding: 5px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.PhotoDescriptionBlock {
  width: 100%;
  max-width: 420px;
  min-width: 300px;
  min-height: 632px;
  display: flex;
  flex-flow: column;
  /* box-sizing: border-box; */
  padding-top: 2rem;
  flex: 1;
}

.PhotoDescription {
  flex: 1;
  margin: 0 5px;
  width: 100%;
  min-width: 300px;
  border-top: 1px solid lightgrey;
  padding: 15px;
  box-sizing: border-box;
  justify-content: left;
  text-align: left;
  line-height: 1.4;
  /* background-color: #ebedef; */
  /* background-color: var(--main-mid-dark); */
  /* background-color: rgba(83, 97, 98, 0.3); */
  border-radius: 3px;
}

.PhotoDescriptionDescr {
  flex: 1;
  margin: 0 5px;
  width: 100%;
  min-width: 300px;
  max-height: fit-content;
  min-height: 150px;

  padding: 15px;
  box-sizing: border-box;
  justify-content: left;
  text-align: left;
  font-size: 1.1rem;
  /* background-color: #ebedef; */
  /* background-color: rgba(83, 97, 98, 0.3); */
  border-radius: 3px;
}

.PhotoDescriptionAlb {
  display: inline-block;
  margin: 10px 0 10px 0;
  width: 100%;
  max-width: 1500px;
  border-bottom: 1px solid rgb(226, 225, 225);

  padding: 15px 5px 15px 5px;
  box-sizing: border-box;
  font-size: 1.1rem;
  background-color: var(--main-light);
  border-radius: 3px;
}

.PhotoDescriptionDescr span {
  text-align: left;
  font-size: 1rem;
  font-weight: bold;
}

.PhotoDescription ul {
  list-style: none;
}

.BlockTitle {
  text-align: left;
  margin: 5px auto 5px auto;
  width: 80%;
}

.PhotoDescription a {
  outline: none;
}

.PhotoPage a {
  outline: none;
}

.Message h4,
h5 {
  text-align: center;
}

.Message h4 {
  padding-top: 70px;
}

/* .MiniSiteMap {
  display: flex;
  flex-flow: row;
  width: 100%;
  background-color: LightSlateGray;
  height: 37px;
  position: fixed;
  margin: -3px 0 15px 0;
  border-top: 7px solid rgba(255, 255, 255, 0.8);
  background-clip: padding-box;
} */

.albumNavEmpty {
  width: 100%;
  height: 65px;
}

@media (max-width: 1200px) {
  .PhotoDescriptionBlock {
    flex-flow: row;
    max-width: 1200px;
    min-height: fit-content;
    padding-top: 5px;
  }

  .PhotoAlbDisplay {
    margin: 0 0 0 0;
  }

  .PhotoDisplay {
    flex-flow: column;
  }
  .PhotoDescriptionDescr {
    max-width: 1000px;
  }

  .PhotoDescription {
    max-width: 1000px;
    border-top: none;
  }
  .PhotoDescriptionAlb {
    width: 98%;
  }
  .PhotoDescriptionDescr span {
    text-align: left;
    font-size: 1.1rem;
    font-weight: bold;
  }
}

@media (max-width: 699px) {
  .Viewer {
    width: 100%;
    height: 400px;
  }

  .PhotoDescription {
    margin: 5px 0;
    border-top: none;
  }
  .PhotoDescriptionDescr {
    margin: 5px 0;
    min-height: min-content;
  }

  .PhotoDescriptionDescr span {
    font-size: 1rem;
    margin: 0;
  }
  .PhotoDescriptionBlock {
    flex-flow: column;
    margin: 0;
    padding-top: 5px;
  }
  .PhotoAlbDisplay {
    margin: 0 0 0 0;
  }
  .PhotoDescriptionAlb {
    width: 98%;
  }

  .Caption {
    margin: 5px auto 5px auto;
  }

  .Caption span {
    font-size: 1.5em;
  }
  .MiniSiteMap {
    display: none;
    /* flex-flow: column;
    min-height: 113px; */
  }
}
