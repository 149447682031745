.Logo {
  /* background-color: #f5f5f5; */
  background-color: var(--main-dark);
  padding: 1px 5px 1px 5px;
  height: 100%;
  box-sizing: border-box;
  border-radius: 5px;
}

.Logo img {
  height: 90%;
}

.LogoSide {
  background-color: var(--main-dark);
  padding: 1px 5px 1px 5px;
  /* height: 100%; */
  box-sizing: border-box;
  border-radius: 5px;
}

.LogoSide img {
  width: 235px;
}

@media (max-width: 699px) {
  .Logo img {
    height: 85%;
  }
}

@media (max-width: 400px) {
  .LogoSide img {
    width: 150px;
  }
}
